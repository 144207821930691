.order-section {
  padding-top: ac(108px, 100px);
  padding-bottom: ac(64px, 30px);
  overflow: hidden;
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(51px, 30px);
    position: relative;
    z-index: 1;
  }

  &__bg {
    position: absolute;
    bottom: ac(-64px, -40px);
    left: ac(-109px, -21px);
    --arw: 708.22;
    --arh: 454.11;
    width: ac(708px, 300px);
    z-index: -1;
  }

  &__main-form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 951px) {
      flex-direction: column;
      grid-row-gap: 30px;
    }

    @media (max-width: 551px) {
      grid-row-gap: 24px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 57.52%;

    @media (max-width: 1100px) {
      width: 52%;
    }

    @media (max-width: 951px) {
      width: 100%;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(29px, 18px);
    }
  }

  &__item-subtitle {
    text-transform: none;
    font-feature-settings: 'pnum' on, 'lnum' on;
    letter-spacing: 0;

    &.button-text-2 {
      line-height: 1.3;
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px) !important;
    }
  }

  &__item {
    width: 100%;
    border: 1px solid var(--cl-lilac-450);
    padding-top: ac(31px, 20px);
    padding-bottom: ac(39px, 20px);
    padding-left: ac(39px, 16px);
    padding-right: ac(39px, 16px);
    border-radius: ac(12px, 8px);
    background: var(--cl-white);

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__item-title {
    color: var(--cl-lilac-500);

    &.headline-6 {
      letter-spacing: 0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-family: var(--font-second);
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__item-form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(16px, 12px);
    grid-row-gap: 16px;

    > * {
      &:nth-child(2n + 1) {
        &:last-child {
          grid-column: 1 / -1;
        }
      }
    }

    @media (max-width: 551px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__container {
    padding-top: ac(7px, 0px);
    width: 38.6%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 1200px) {
      width: 40%;
    }

    @media (max-width: 1100px) {
      width: 45%;
    }

    @media (max-width: 951px) {
      width: 100%;
      max-width: 560px;
    }
  }

  &__title {
    &.headline-6 {
      font-family: var(--font-second);

      &:not(:last-child) {
        margin-bottom: ac(24px, 20px);
      }
    }
  }

  &__subtitle {
    color: var(--cl-lilac-500);
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &.subtitle-2 {
      font-family: var(--font-second);
    }
  }

  &__result {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    padding-bottom: 7px;
    border-bottom: 1px solid var(--cl-lilac-450);

    &:not(:last-child) {
      margin-bottom: ac(32px, 22px);
    }
  }

  &__result-info {
  }

  &__payment-method {
    padding-top: 8px;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(36px, 22px);
    }
  }

  &__checkboxes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 3px;
    grid-column-gap: ac(27px, 18px);
    grid-row-gap: 16px;

    &:not(:last-child) {
      margin-bottom: ac(19px, 16px);
    }
  }

  &__payment-info {
    font-size: 14px;
    strong {
      font-weight: 600;
      color: var(--cl-red);
    }

    span {
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__payment-input {
    width: 100%;

    .promo-container {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 12px;

      .meta-input {
        width: 100%;
      }

      .btn {
        padding-left: 12px;
        padding-right: 12px;
        height: 44px;
        min-height: 44px;
        white-space: nowrap;

        .btn__text {
          font-size: ac(16px, 15px);
        }

        @media (max-width: 1300px) {
          @media (min-width: 1024px) {
            font-size: 14px;
          }
        }
      }

      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .btn {
          margin-left: auto;
        }
      }

      @media (max-width: 640px) {
        .btn {
          width: 100%;
        }
      }
    }
  }



  &__result {
    width: 100%;

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__result-sum {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--cl-lilac-450);

    .order-section__result-price {
      font-weight: 700;
    }

    .order-section__subtitle {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(8px, 12px);
    }
  }

  &__result-price {
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--cl-lilac-500);
    font-weight: 600;

    span {
      color: var(--cl-lilac-850);
    }
  }

  &__result-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    .btn {
      width: 100%;
      min-height: 48px;
      height: 48px;

      &__text {
        font-size: 16px;
        letter-spacing: 0.04em;
      }

      &:not(.btn-second) {
        border-color: var(--cl-lilac-450);

        &:not(:hover) {
          background-color: var(--cl-white);
        }
      }
    }
  }

  &__cart {
    width: 100%;
    padding: ac(31px, 24px) ac(23px, 19px) ac(12px, 7px) 19px;
    border-radius: 12px;
    border: 1px solid var(--cl-lilac-450);

    &:not(:last-child) {
      margin-bottom: ac(24px, 22px);
    }
  }

  &__cart-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    grid-row-gap: ac(20px, 16px);
  }

  &__cart-item {
    padding-bottom: ac(19px, 16px);
    padding-left: ac(32px, 24px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(16px, 14px);
    position: relative;
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid var(--cl-lilac-450);
    }

    @media (max-width: 451px) {
      padding-left: 0;
      align-items: flex-start;
    }
  }

  &__cart-item-link {
    display: none;
  }

  &__cart-item-image {
    --arw: 1;
    --arh: 1;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--cl-lilac-450);
    width: ac(108px, 90px);

    @media (max-width: 451px) {
      margin-top: 34px;
    }
  }

  &__cart-item-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: ac(16px, 14px);
    grid-row-gap: 16px;

    @media (max-width: 390px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__cart-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
  }

  &__cart-item-title {
    &.subtitle-4 {
      line-height: 1.2;
    }

    &:not(:last-child) {
      margin-bottom: ac(10px, 9px);
    }
  }

  &__cart-item-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--cl-lilac-850);
    font-feature-settings: 'pnum' on, 'lnum' on;
    max-width: 100%;

    &.with-sale {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &:not(.with-sale) {
      white-space: nowrap;
    }
  }

  &__cart-item-not-sale {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }

  &__cart-info-price {
    font-size: ac(24px, 22px);
    font-weight: 500;
    font-family: var(--font-main);
    line-height: 1.2;
    color: var(--cl-lilac-850);
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__cart-info-old {
    font-size: 13px;
    font-weight: 500;
    font-family: var(--font-main);
    line-height: 18px;
    color: var(--cl-lilac-500);
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-decoration: line-through;
  }

  &__cart-info-sale {
    font-size: ac(24px, 22px);
    font-weight: 700;
    font-family: var(--font-main);
    line-height: 1;
    color: red;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__cart-info-unit {
    font-size: ac(24px, 22px);
    font-weight: 500;
    font-family: var(--font-main);
    line-height: 1.2;
    color: var(--cl-lilac-500);
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__cart-info-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    color: var(--cl-lilac-500);
    margin-left: ac(5px, 4px);
    text-transform: uppercase;
    white-space: nowrap;
    font-feature-settings: 'pnum' on, 'lnum' on;

    span {
      font-size: ac(22px, 22px);
      font-weight: 800;
      line-height: normal;
      font-family: var(--font-second);
      color: var(--cl-lilac-450);
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
  }

  &__cart-item-counter {
    min-width: 74px;
  }

  &__cart-item-close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: ac(4px, 0px);
    top: calc(50% - ac(10px, 8px));
    transform: translateY(-50%);
    color: var(--cl-red);
    transition: 0.3s;
    cursor: pointer;
    font-size: 15px;

    &:hover {
      color: var(--cl-lilac-500);
    }

    @media (max-width: 451px) {
      top: 0;
      left: 0;
      transform: none;
    }
  }
}
