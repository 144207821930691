.products-section {
  --slider-margin: ac(48px, 24px);
  padding-top: ac(80px, 30px);
  padding-bottom: ac(80px, 30px);
  overflow: visible;
  background: var(--cl-lilac-50);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: var(--slider-margin);
    }
  }

  &__title {
    width: 100%;
    max-width: ac(600px, 400px);
  }

  &__top-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 49.31%;
  }

  &__buttons {
    margin-top: auto;
  }

  &__slider {
    overflow: visible;
    position: relative;
    width: 100%;
    max-width: 100%;

    .swiper {
      overflow: visible;
      z-index: 30;

      .product-card {
        &__content {
          @media (max-width: 1300px) {
            @media (min-width: 1280px) {
              padding-left: 12px;
              padding-right: 12px;
            }
          }
        }
      }

      @media (max-width: 640px) {
        max-width: 270px;
        margin-left: 0;
      }
    }

    @media (min-width: 641px) {
      .swiper-buttons {
        position: absolute;
        z-index: 5;
        right: 0;
        top: calc(var(--slider-margin) * -1);
        transform: translateY(-100%);

        .swiper-button {
          margin-top: 0;
        }
      }
    }

    @media (max-width: 640px) {
      .swiper-buttons {
        width: 100%;
        justify-content: center;

        .swiper-button {
          margin-top: 32px;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .products-section {
      &__top {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__top-content {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
}
