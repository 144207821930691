.cart-section {
  padding-top: ac(108px, 100px);
  padding-bottom: ac(64px, 40px);
  overflow: hidden;
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(40px, 30px);
  }

  &__title {
    text-transform: none;
    &:not(:last-child) {
      margin-bottom: ac(28px, 22px);
    }
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  &__top {
    border: 1px solid var(--cl-lilac-450);
    background: var(--cl-lilac-50);
    border-radius: 8px;
    padding-left: ac(56px, 32px);
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr)) 154px 134px;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(32px, 28px);
    }
  }

  &__top-item {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      border-right: 1px solid var(--cl-lilac-450);
    }

    &:nth-child(2) {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:first-child {
      font-weight: 700;
      font-size: ac(18px, 16px);
      line-height: 1.2;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      padding-left: 0;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(20px, 16px);

    &:not(:last-child) {
      margin-bottom: ac(0px, 10px);
    }
  }

  &__item {
    width: 100%;
    display: grid;
    grid-template-columns: ac(140px, 110px) repeat(1, minmax(0, 1fr)) 154px 134px;
    padding-left: ac(56px, 32px);
    position: relative;
    padding-bottom: ac(19px, 16px);
    border-bottom: 1px solid var(--cl-lilac-450);

    &:last-child {
      border-color: transparent;
    }
  }

  &__item-link {
    display: none;
  }

  &__item-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item-image {
    width: 100%;
    --arw: 140;
    --arh: 105;
    border: 1px solid var(--cl-lilac-450);
    border-radius: 8px;
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 20px;
    padding-left: ac(40px, 20px);
  }

  &__item-title {
    @media (min-width: 1400px) {
      &.subtitle-2 {
        font-size: 26px;
        line-height: 1.2;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 14px);
    }
  }

  &__item-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-row-gap: 16px;

    @media (max-width: 1023px) {
      flex-wrap: wrap;
    }
  }

  &__item-select {
    width: ac(420px, 220px);
    margin-right: ac(20px, 16px);

    @media (max-width: 1023px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__info-unit {
    color: var(--cl-lilac-500);
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-weight: 500;

    &:not(:last-child) {
      margin-right: ac(8px, 6px);
    }
  }

  &__info-price {
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-weight: 500;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__info-old {
    font-size: 13px;
    font-weight: 500;
    font-family: var(--font-main);
    line-height: 18px;
    color: var(--cl-lilac-500);
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-decoration: line-through;
  }

  &__info-sale {
    font-family: var(--font-main);
    color: var(--cl-red);
    font-feature-settings: 'pnum' on, 'lnum' on;

    &.subtitle-3 {
      font-weight: 700;
    }
  }

  &__info-text {
    color: var(--cl-lilac-500);
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-weight: 700;

    span {
      color: var(--cl-lilac-450);
    }
  }

  &__item-counter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  &__item-counter {
    &.counter {
      width: ac(144px, 128px);
      height: ac(48px, 40px);
      max-height: ac(48px, 40px);
      border-color: var(--cl-lilac-50);
      background: var(--cl-lilac-50);

      .counter-btn {
        width: ac(46px, 38px);
        height: ac(46px, 38px);
        border-color: var(--cl-lilac-450);
        background-color: var(--cl-white);

        i {
          font-size: 15px;
          color: var(--cl-lilac-850);
        }

        &:hover {
          border-color: var(--cl-lilac-500);
          background-color: var(--cl-lilac-450);
        }
      }

      input {
        background-color: var(--cl-lilac-50);
      }
    }
  }

  &__item-sum {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    color: var(--cl-lilac-500);
    font-feature-settings: 'pnum' on, 'lnum' on;

    &.subtitle-1 {
      font-weight: 600;
    }

    span {
      color: var(--cl-lilac-850);
    }
  }

  &__item-close {
    position: absolute;
    left: ac(6px, 0px);
    top: calc(50% - ac(9px, 8px));
    transform: translateY(-50%);
    font-size: ac(20px, 18px);
    color: var(--cl-red);
    transition: 0.3s;

    &:hover {
      color: var(--cl-lilac-500);
    }
  }

  &__item-top,
  &__item-bottom {
    @media (min-width: 641px) {
      display: none;
    }
  }

  &__bottom {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: auto;

    @media (max-width: 451px) {
      width: 100%;
      align-items: stretch;
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: ac(19px, 10px);
    padding-bottom: ac(11px, 14px);
    border-bottom: 1px solid #dfdfe3;
    gap: ac(20px, 16px);

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }

    @media (max-width: 451px) {
      width: 100%;
    }
  }

  &__sum-title {
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--cl-lilac-500);

    &.headline-6 {
      font-family: var(--font-second);
    }
  }

  &__sum-price {
    letter-spacing: 0.01em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--cl-lilac-500);

    span {
      color: var(--cl-lilac-850);
    }
  }

  &__buttons {
    justify-content: flex-end;

    @media (max-width: 451px) {
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }

  @media (max-width: 640px) {
    &__top {
      display: none;
    }

    &__item {
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-wrap: wrap;
      border: 1px solid var(--cl-lilac-450) !important;
      border-radius: 8px;
      padding-top: 0;
      overflow: hidden;
    }

    &__item-top {
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px);
      margin-bottom: 10px;
      background: var(--cl-lilac-50);
      border-bottom: 1px solid var(--cl-lilac-450);
      padding: 10px 20px;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.2;
      letter-spacing: 0.04em;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }

    &__item-bottom {
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px);
      margin-bottom: 10px;
      background: var(--cl-lilac-50);
      border-bottom: 1px solid var(--cl-lilac-450);
      border-top: 1px solid var(--cl-lilac-450);
      padding: 0 20px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.2;
      letter-spacing: 0.04em;
      display: flex;
      align-items: stretch;

      span {
        padding: 10px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &:not(:last-child) {
          border-right: 1px solid var(--cl-lilac-450);
        }
      }
    }

    &__item-image-container {
      width: 120px;
    }

    &__item-image {
      --arw: 1;
      --arh: 1;
    }

    &__item-content {
      flex: 1;
      padding-right: 0;
      padding-left: 16px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &__item-close {
      left: auto;
      top: 12px;
      transform: none;
      right: 16px;
    }

    &__item-counter-container {
      margin-left: auto;
      margin-right: auto;
    }

    &__item-sum {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 425px) {
    &__item {
      padding-left: 16px;
      padding-right: 16px;
    }

    &__item-image-container {
      width: 90px;
      margin-bottom: auto;
      margin-top: 5px;
    }

    &__item-bottom,
    &__item-top {
      margin-left: -16px;
      margin-right: -16px;
      width: calc(100% + 32px);
      padding-left: 0;
      padding-right: 0;
    }

    &__item-counter-container {
      margin-left: 20px;

      @media (max-width: 390px) {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 374px) {
    &__item-top {
      text-align: left;
      justify-content: flex-start;
    }

    &__item-content {
      width: 100%;
      flex: auto;
      padding-left: 0;
      padding-top: 16px;
    }

    &__item-image-container {
      width: 120px;
      margin-left: auto;
      margin-right: auto;

    }

    &__item-title {
      width: 100%;
      text-align: center;
    }

    &__item-bottom {
      padding-left: 0;
      padding-right: 0;
    }

    &__item-counter-container {
      margin-left: -10px;
    }
  }
}
