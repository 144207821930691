/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  /*-moz-appearance: textfield;*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number']::-webkit-inner-spin-button {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.meta-input,
.meta-select,
.meta-upload-file {
  label,
  .label {
    font-family: var(--font-main);
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: var(--cl-lilac-500);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.3s ease;
    position: relative;
    z-index: 1;
    margin-bottom: 4px;

    span {
      margin-left: 2px;
      display: inline-block;
      color: var(--cl-lilac-850);
      transform: scale(1.8) translateY(2px);
    }
  }
}

.dark-bg {
  .meta-input,
  .meta-select,
  .meta-upload-file {
    label,
    .label {
      color: var(--cl-white);
    }
  }
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  input,
  textarea {
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--cl-lilac-850);
    border: 1px solid var(--cl-lilac-450);
    border-radius: 6px;
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
      caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: 10px 16px;
    height: 44px;
    background: var(--cl-lilac-450);
    @mixin transparent-bg-input var(--cl-lilac-850);
    font-feature-settings: 'pnum' on, 'lnum' on;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08),
      0px 1px 3px 1px rgba(0, 0, 0, 0.12);

    &::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-lilac-850);
      opacity: 1;
    }

    &:focus {
      border-color: var(--cl-lilac-500);
      caret-color: var(--cl-black);
      box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
    }

    /*&.error {
      border-color: var(--cl-red);
      box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
    }*/
  }

  textarea {
    padding-top: 12px;
    min-height: ac(88px, 96px);
    max-height: ac(88px, 96px);
    overflow-y: auto;
    resize: none;
    clip-path: inset(-1px -1px -1px -1px round 6px);

    scrollbar-width: thin;
    scrollbar-color: var(--cl-primary) transparent;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-primary);
      border-radius: 2px;
    }
  }

  /*&.focused {
    label {
      top: 0;
      transform: translateY(-50%);
      font-size: 12px;
    }
  }*/

  &.dark-border {
    input,
    textarea {
      border-color: var(--cl-black);

      &:focus {
        border-color: var(--cl-orange);
      }
    }
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-white);
  }

  /*&__error {
    color: var(--cl-red);
  }*/

  .input-info {
    font-family: var(--font-main);
    font-weight: 500;
    font-size: 12px;
    line-height: 1.2;
    padding-top: 0;
    transition: all 0.25s ease;
    max-height: 0;
    overflow: hidden;

    &.active {
      padding-top: 4px;
      max-height: 60px;
    }

    &.active-not-valid {
      color: red;
    }

    &.active-valid {
      color: var(--cl-green);
    }
  }

  &.form-type {
    label {
      line-height: 1.4;
      margin-bottom: ac(9px, 6px);
      color: var(--cl-lilac-850);

      span {
        color: var(--cl-red);
      }
    }

    input,
    textarea {
      background: var(--cl-lilac-50);

      &::placeholder {
        color: var(--cl-lilac-500);
      }

      &:not(:focus) {
        box-shadow: none;
      }
    }
  }
}

.is-safari {
  .meta-input {
    textarea {
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: initial;
        border-radius: initial;
      }

      &::-webkit-scrollbar-thumb {
        background: initial;
        border-radius: initial;
      }
    }
  }
}

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  /*label,
  .label {
    font-family: var(--font-main);
    color: var(--cl-leaf);
    font-size: ac(14px, 12px);
    font-weight: 600;
    padding-bottom: ac(13px, 6px);
    cursor: pointer;
  }*/

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(17px, 10px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(13px, 10px);
    }
  }

  &__input {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(17px, 16px);
    line-height: normal;
    font-weight: 900;
    font-family: var(--font-main);
    color: var(--cl-white);
    border: 2px solid var(--cl-black);
    border-radius: 28px;
    background: var(--cl-black);
    transition: all 0.3s ease;
    padding: 4px ac(28px, 18px);
    cursor: pointer;
    position: relative;
    height: ac(44px, 40px);
    text-transform: uppercase;

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
      color: var(--cl-white);
    }

    &:hover {
      background-color: transparent;
      color: var(--cl-black);
    }
  }

  &__file-name {
    max-width: calc(100% - ac(122px, 114px));
    @mixin max-line-length-one;
    font-family: var(--font-second);
    font-size: ac(18px, 15px);
    font-weight: 300;
    color: var(--cl-white);
  }

  &__info {
    font-size: ac(14px, 12px);
    line-height: normal;
    font-weight: 300;
    font-family: var(--font-second);
    font-style: italic;
    color: var(--cl-white);
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-leaf);
      box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-leaf) / 0.3);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__box {
    --size: ac(26px, 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    min-width: var(--size);
    height: var(--size);
    border: 2px solid #888996;
    margin-right: ac(7px, 6px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background: transparent;

    &:hover {
      border-color: var(--cl-lilac-500);
    }

    .check-clip,
    .circle-clip {
      width: 85%;
      height: 85%;
      background-color: var(--cl-red);
      opacity: 0;
      transition: opacity 0.3s ease, background-color 0.3s ease;
    }

    .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
      width: 53.85%;
      height: 53.85%;
      border-radius: 50%;
      overflow: hidden;
      transform: translateZ(0);

      @media (max-width: 640px) {
        width: 65%;
        height: 65%;
      }
    }

    i {
      opacity: 0;
      font-size: ac(18px, 16px);
      color: var(--cl-black);
      transition: opacity 0.3s ease, color 0.3s ease;
    }
  }

  input {
    display: none;
  }

  input[type='checkbox'] {
    + .meta-checkbox__box {
      border-radius: 4px;
    }
  }

  input[type='radio'] {
    + .meta-checkbox__box {
      border-radius: 50%;
    }
  }

  &__text {
    font-size: ac(16px, 14px);
    font-weight: 700;
    line-height: 1.2;
    font-family: var(--font-main);
    color: var(--cl-lilac-850);
    text-transform: uppercase;
    letter-spacing: 0.04em;

    a {
      font: inherit;
      color: var(--cl-orange);
      text-decoration: underline;
      text-decoration-color: var(--cl-orange);
      transition: all 0.25s ease;

      &:hover {
        color: var(--cl-black);
        text-decoration-color: var(--cl-black);
      }
    }
  }

  input:checked ~ .meta-checkbox__box {
    border-color: var(--cl-lilac-500);
    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
      color: var(--cl-red);
    }

    &:hover {
      border-color: var(--cl-lilac-850);
    }
  }
}

.meta-select-nova {
  position: relative;

  div[role="status"] {
    position: absolute;
    top: ac(28px, 26px);
    left: 0;
    bottom: 0;
    z-index: 50;
    height: 44px;
    min-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    pointer-events: none;
    opacity: 0;
    transition: .3s;

    svg {
      fill: var(--cl-light-gray);
      color: var(--cl-lilac-500);
    }
  }

  &.is-loading {
    pointer-events: none;
    div[role="status"] {
      opacity: 1;
    }

    .choices {
      &__inner {
        background-color: #eaeaea !important;
        border-color: var(--cl-mid-gray) !important;
      }

      &[data-type*='select-one'],
      &[data-type*='select-multiple'] {
        border-color: var(--cl-mid-gray) !important;
      }

      &__item--selectable {
        background-color: #eaeaea !important;

        &.choices__placeholder {
          color: var(--cl-mid-gray) !important;
        }
      }

      &__item {
        color: var(--cl-mid-gray) !important;
      }

      &__list {
        background-color: #eaeaea !important;

        &--dropdown {
          border-color: var(--cl-mid-gray) !important;
        }
      }

      &[data-type*='select-one'] .choices__input {
        border-color: var(--cl-mid-gray) !important;
      }
    }
  }

  .meta-select {
    width: 100%;
  }
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  select {
    opacity: 0;
    height: ac(50px, 48px);
  }

  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;

    .choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
      max-height: 240px;
    }

    &.is-disabled {
      .choices__list {
        background-color: #eaeaea !important;
      }
    }

    &__inner {
      border: 1px solid var(--cl-lilac-450);
      border-radius: 6px;
      outline: none;
      transition: box-shadow 0.3s ease, border-color 0.3s ease,
        border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      padding: 5px ac(13px, 12px) !important;
      background: var(--cl-white);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      cursor: pointer;
      font-size: 16px;
      line-height: 1.2;
      font-weight: 600;
      letter-spacing: 0.04em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-family: var(--font-main);
      color: var(--cl-lilac-850);
      height: 44px;
      min-height: 44px;

      &:hover {
        border-color: var(--cl-lilac-850);
      }
    }
    &.is-open {
      &:not(.is-flipped) {
        .choices__inner {
          border-radius: 6px 6px 0 0;
        }

        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          border-top: none;
          margin-top: 0;
          border-radius: 0 0 6px 6px;
        }
      }

      &.is-flipped {
        .choices__inner {
          border-radius: 0 0 6px 6px;
        }

        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 0;
          border-bottom: none;
          border-radius: 6px 6px 0 0;
        }
      }
    }

    &__list--single {
      .choices__item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__placeholder {
      color: var(--cl-lilac-850);
      opacity: 1;
    }

    &__list {
      padding: 0;
      background-color: var(--cl-white);

      .choices__item {
        padding-right: ac(16px, 12px);
        font-size: 16px;
        line-height: 1.2;
        font-weight: 600;
        letter-spacing: 0.04em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        font-family: var(--font-main);

        &.is-selected {
          color: var(--cl-lilac-850);
        }
      }

      /*&--multiple {
        color: var(--cl-dark-blue);
        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }*/

      &--dropdown {
        .choices__item {
          color: var(--cl-lilac-500);
          word-break: keep-all;
        }
      }
    }

    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;
      &:after {
        border: none;
        border-bottom: 1px solid var(--cl-lilac-500);
        border-right: 1px solid var(--cl-lilac-500);
        content: '';
        display: block;
        height: 5px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: ac(25px, 18px);
        top: 50%;
        transform-origin: 66% 66%;
        transform: rotate(45deg) scale(1.5);
        transition: all 0.15s ease-in-out;
        width: 5px;
      }
    }

    &.is-open {
      &:after {
        transform: rotate(-135deg) scale(1.5);
      }
    }

    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: transparent;

      &:not(.is-selected) {
        color: var(--cl-primary);
      }
    }

    &__list--dropdown,
    .choices__list[aria-expanded] {
      border: 1px solid var(--cl-lilac-450);
      z-index: 20;
      border-radius: 6px;
    }

    &[data-type*='select-one'] .choices__input {
      margin: 2px;
      max-width: calc(100% - 4px);
      border: 1px solid var(--cl-lilac-500);
      border-radius: 8px;
      background: transparent;
      color: var(--cl-primary);
      font-size: 16px;
      line-height: 1.2;
      font-weight: 600;
      font-family: var(--font-main);
    }

    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }

  &.form-type {
    label {
      line-height: 1.4;
      margin-bottom: ac(9px, 6px);
      color: var(--cl-lilac-850);

      span {
        color: var(--cl-red);
      }
    }
    .choices {
      &__inner {
        background-color: var(--cl-lilac-50);
      }

      &__item--selectable {
        background-color: var(--cl-lilac-50);
        font-size: 14px;
        font-weight: 600;
        line-height: 1.4;

        &.choices__placeholder {
          color: var(--cl-lilac-500);
        }
      }
    }
  }
}

.counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  border: 1px solid var(--cl-lilac-450);
  height: 40px;
  max-height: 40px;
  width: 128px;
  overflow: hidden;
  background: var(--cl-white);
  position: relative;

  .counter-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--cl-white);
    line-height: 1;
    transition: color 0.25s ease, background-color 0.25s ease;
    cursor: pointer;
    height: 39px;
    width: 39px;
    border-radius: 50%;
    border: 1px solid var(--cl-lilac-500);
    background: var(--cl-lilac-500);

    &:hover {
      background-color: var(--cl-lilac-450);
      color: var(--cl-lilac-850);
    }
  }

  input {
    padding: 5px 0;
    font-family: var(--font-main);
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.02em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    @mixin transparent-bg-input var(--cl-primary);
    width: 44px;
    height: 40px;
    text-align: center;
  }

  /*&.counter-small {
    height: 32px;
    border-radius: 6px;

    .counter-btn {
      font-size: 32px;
    }

    input {
      width: 32px;
      height: 32px;
    }
  }*/

  [role='status'] {
    svg {
      fill: var(--cl-primary);
      color: color-mod(var(--cl-primary) a(40%));
    }
  }

  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &.counter-input-num {
    border-radius: 6px;
    border: 1px solid var(--cl-lilac-450);
    height: 44px;
    max-height: 44px;
    width: 74px;
    background: var(--cl-lilac-50);
    padding-left: 10px;

    input {
      padding: 0;
      width: 32px;
      @mixin transparent-bg-input var(--cl-primary);
      background: transparent;
      text-align: left;
      color: var(--cl-primary);
      letter-spacing: -0.04em;
      font-size: 17px;
    }

    .counter-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 7px;
      color: var(--cl-lilac-500);
      line-height: 1;
      height: 22px;
      width: 22px;
      border-radius: 0;
      border: none;
      background: transparent;
      position: absolute;
      right: 8px;

      &.counter-btn-minus {
        bottom: 0;
      }

      &.counter-btn-plus {
        top: 0;

        i {
          transform: scaleY(-1);
        }
      }

      &:hover {
        background-color: transparent;
        color: var(--cl-lilac-950);
        filter: drop-shadow(0 0 1px var(--cl-lilac-500));
      }
    }
  }
}
