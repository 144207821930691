.footer {
  background: var(--cl-lilac-50);
  padding-top: ac(48px, 30px);
  padding-bottom: 32px;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
  }

  &__top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: ac(35px, 16px);
    border-bottom: 1px solid var(--cl-lilac-450);

    &:not(:last-child) {
      margin-bottom: ac(32px, 16px);
    }

    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-row-gap: 24px;
    }

    @media (max-width: 551px) {
      align-items: flex-start;
      grid-row-gap: 16px;
    }
  }

  &__logo {
    --arw: 152;
    --arh: 131;
    width: ac(152px, 120px);
    transition: all 0.3s ease;
    margin-right: ac(56px, 20px);

    img {
      object-fit: contain;
      object-position: left;
    }

    &:hover {
      transform: scale(0.9);
      opacity: 0.8;
    }
  }

  &__info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    grid-column-gap: ac(24px, 20px);

    @media (max-width: 1100px) {
      width: 100%;
    }

    @media (max-width: 951px) {
      flex-wrap: wrap;
      grid-row-gap: 20px;
    }

    @media (max-width: 551px) {
      grid-row-gap: 16px;
    }
  }

  &__nav {
    width: 37.58%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @media (min-width: 1420px) {
      margin-right: ac(25px, 0px);
    }

    @media (max-width: 951px) {
      width: 100%;
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__nav-title,
  &__contacts-title {
    font-family: var(--font-main);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: 0.02em;

    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &__nav-title {
    @media (max-width: 951px) {
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 551px) {
      margin-left: 0;
    }
  }

  &__nav-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(16px, 14px);
    grid-row-gap: ac(11px, 8px);
  }

  &__nav-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a {
      font-size: ac(18px, 16px);
      line-height: 1.36;
      font-weight: 500;
      font-family: var(--font-main);
      position: relative;
      transition: color 0.3s ease;
      z-index: 1;
      white-space: nowrap;

      &:before {
        position: absolute;
        bottom: 0;
        left: -2px;
        right: -2px;
        height: 0;
        background: var(--cl-lilac-450);
        transition: background-color 0.3s ease, height 0.3s ease;
        content: '';
        z-index: -1;
      }

      &:hover {
        color: var(--cl-lilac-950);

        &:before {
          height: 45%;
        }
      }
    }

    @media (max-width: 951px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 551px) {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__contacts {
    width: 23.62%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &.location-type {
      @media (min-width: 952px) {
        width: 31.59%;
      }
    }

    @media (max-width: 951px) {
      width: 45%;
    }

    @media (max-width: 551px) {
      width: 100%;
    }
  }

  &__contacts-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ac(24px, 16px);
    margin-top: ac(0px, -2px);

    @mixin media 551 {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      max-width: 335px;
    }
  }

  &__contacts-grid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(16px, 12px);
  }

  &__contact-link,
  &__location-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ac(12px, 10px);

    &:hover {
      .footer__contact-icon,
      .footer__location-icon {
        i {
          color: var(--cl-lilac-950);
        }

        svg {
          path {
            fill: var(--cl-lilac-950);
          }
        }
      }

      .footer__contact-text {
        color: var(--cl-lilac-950);

        &:before {
          height: 45%;
        }
      }

      .footer__location-text {
        color: var(--cl-lilac-500);
      }
    }
  }

  &__contact-text,
  &__location-text {
    font-size: ac(18px, 16px);
    line-height: 1.36;
    font-weight: 500;
    font-family: var(--font-main);
    transition: all 0.3s ease;
    max-width: calc(100% - ac(32px, 28px));
    position: relative;
    z-index: 1;
    white-space: nowrap;
    font-feature-settings: 'pnum' on, 'lnum' on;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--cl-lilac-450);
      transition: background-color 0.3s ease, height 0.3s ease;
      content: '';
      z-index: -1;
    }
  }

  &__location-text {
    white-space: normal;
    &:before {
      display: none;
    }
  }

  &__contact-icon,
  &__location-icon {
    i {
      font-size: ac(20px, 18px);
      color: var(--cl-lilac-500);
      transition: 0.3s;
    }

    svg {
      path {
        transition: 0.3s;
        color: var(--cl-lilac-500);
      }
    }
  }

  &__bottom-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-row-gap: 28px;
    grid-column-gap: ac(48px, 20px);
    width: 100%;

    @media (max-width: 851px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__bottom-info {
    flex: 1;
    max-width: 1024px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-row-gap: 28px;
    grid-column-gap: ac(39px, 20px);

    @media (max-width: 551px) {
      grid-row-gap: 10px;
    }
  }

  &__bottom-info-text {
    p {
      leading-trim: both;
      text-edge: cap;
      letter-spacing: 0.01em;
      font-size: 14px;
      line-height: normal;
      font-weight: 400;
    }

    @media (min-width: 1439px) {
      padding-right: 10px;
    }
  }

  &__bottom-info-link {
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-size: 14px;
    line-height: normal;
    font-weight: 600;
    position: relative;
    transition: color 0.3s ease;
    z-index: 1;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--cl-lilac-450);
      transition: background-color 0.3s ease, height 0.3s ease;
      content: '';
      z-index: -1;
    }

    &:hover {
      color: var(--cl-black);

      &:before {
        height: 45%;
      }
    }

    &:not(:last-of-type) {
      &:after {
        content: '/';
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(50%) scaleY(0.6);
        right: calc((ac(39px, 20px) / 2) * -1);
        font-size: ac(18px, 16px);
        font-weight: 800;
        line-height: normal;
        font-family: var(--font-second);
        color: var(--cl-lilac-450);
      }
    }
  }

  &__bottom-info-image {
    --arw: 152;
    --arh: 32;
    width: ac(152px, 120px);
    margin-left: ac(10px, 0px);

    @media (max-width: 1150px) {
      @media (min-width: 641px) {
        margin-left: ac(-18px, -16px);
      }
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 16px;

    @media (max-width: 551px) {
      position: absolute;
      top: calc(103px / 2);
      transform: translateY(-50%);
      right: 0;
    }
  }

  &__socials-text {
    grid-column-gap: 16px;
    max-width: 156px;
    width: auto;

    p {
      font-size: ac(16px, 14px);
      font-weight: 500;
      line-height: normal;
      color: var(--cl-darked-gray);
    }
  }
}
