/* ----------------------- Common desktop header ----------------------- */
.top-header {
  width: 100%;
  z-index: 999;
  transition: all 0.3s ease;
  /*overflow: hidden;*/
  opacity: 1;
  background: var(--cl-lilac-50);
  min-height: ac(32px, 24px);
  padding: ac(5px, 9px) 0;
  position: relative;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transition: width 0.3s ease;
    width: calc(var(--th-value, 100) / var(--th-max-value, 1) * 100%);
    background: var(--cl-amber);
  }

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    height: 1px;
    background: var(--cl-amber);
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    content: '';
  }

  &.scrolled {
    /*    margin-top: 0;
    max-height: 0;
    opacity: 0;*/
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 20;
  }

  &__text {
    p {
      font-family: var(--font-main);
      font-weight: 600;
      font-size: 12px;
      line-height: normal;

      @mixin sm {
        letter-spacing: 0.1px;
        text-align: center;

        span {
          font-weight: 500;
          font-size: ac(16px, 14px);
          line-height: 1.4;
        }
      }
    }

    @mixin media 451 {
      max-width: 300px;
      width: 100%;
      margin-right: auto;
    }
  }
}

.header-banner {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0px 12px;
  overflow: hidden;
  width: 100%;
  max-height: 42px;

  padding: 0 20px;
  color: var(--cl-lilac-850);
  background: var(--cl-lilac-400);

  transition: all ease 250ms;

  @media (max-width: 691px) {
    max-height: 60px;
  }

  @media (max-width: 370px) {
    max-height: 70px;
  }

  * {
    color: var(--cl-lilac-850);
  }

  &:hover {
    background: var(--cl-lilac-450);
  }
}

.header-banner__icon {
  --size: ac(22px, 18px);
  width: var(--size);
  height: var(--size);
  background: var(--cl-lilac-50);
  mask-image: url('../../public/images/black-friday-icon.png');
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 370px) {
    --size: 15px
  }
}

.header-banner__block {
  display: flex;
  align-items: center;
  gap: ac(8px, 6px);
  padding-top: ac(10px, 6px);
  padding-bottom: ac(10px, 6px);

  height: auto;

  @media (max-width: 691px) {
    padding: 4px 0;
  }

  span {
    color: var(--light-red);
  }

  p {
    position: relative;
  }

  * {
    font-size: ac(14px, 12px);
    font-weight: 500;
    /* line-height: 1; */

    @media (max-width: 370px) {
      font-size: 10px;
    }
  }

  &.highlight {
    padding: 4px ac(12px, 8px);
    background: var(--cl-black);
    color: var(--cl-white);

    * {
      font-size: ac(16px, 14px);
      font-weight: 700;
      color: var(--cl-white);
      text-transform: uppercase;

      @media (max-width: 451px) {
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
      }

      @media (max-width: 370px) {
        font-size: 10px;
      }
    }

    @media (max-width: 451px) {
      width: 100vw;
      min-width: 100vw;
      justify-content: center;
    }
  }
}

.header {
  background: var(--cl-lilac-50);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  /*right: 0;*/
  z-index: 998;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /*box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04), 0px 2px 16px rgba(0, 0, 0, 0.01);

  &.not-shadow {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0), 0px 2px 16px rgba(0, 0, 0, 0);
    &:after {
      opacity: 0 !important;
    }
  }*/

  /*@media (min-width: 902px) {
    max-width: calc(100% - var(--cart-width));
    margin-right: auto;

    .cont {
      width: 91%;

      @mixin media 1500 {
        @mixin min-media 1400 {
          margin-right: 48px;
        }
      }
    }
  }*/

  &.not-shadow {
    &:after {
      opacity: 0;
    }
  }

  &__categories-menu {
    padding-top: 6px;
    padding-bottom: ac(24px, 20px);
    background: var(--cl-lilac-50);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0), 0px 2px 16px rgba(0, 0, 0, 0);
    pointer-events: none;
    transition: 0.3s;
    clip-path: inset(0 0 -20px 0);
    border-bottom: 1px solid rgba(197, 195, 227, 0.3);

    &.active {
      opacity: 1;
      transform: translateY(99%);
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04),
        0px 2px 16px rgba(0, 0, 0, 0.01);
      pointer-events: auto;
    }
  }

  &__categories-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ac(8px, 6px);
    flex-wrap: wrap;
  }

  &__wrap {
    padding: ac(18px, 16px) 0;
    transition: all 0.3s ease-in-out;
  }

  &.fixed {
    position: fixed;
  }

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    height: 1px;
    background: #dfdfe3;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    content: '';
  }

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

  &.open-menu {
    .cont-second {
      &:before {
        opacity: 1 !important;
      }
    }
  }

  &.active {
    .header-banner {
      opacity: 0;
      max-height: 0;
    }
  }

  &.scrolled {

    .header-banner {
      opacity: 0;
      max-height: 0;
    }

    &:not(.active) {
      /*background-color: transparent;
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);*/

      /*&:after {
        opacity: 0;
      }*/

      /*&:before {
        opacity: 0.5;
        background-color: var(--cl-white);
      }*/

      /*.menu__link {
        color: var(--cl-white);

        &:hover {
          color: var(--cl-pink);
        }
      }

      .burger,
      .menu-toggle {
        svg path {
          &:not(.active) {
            stroke: var(--cl-white);

            &:hover {
              stroke: var(--cl-pink);
            }
          }
        }
      }*/
    }
  }

  .logo {
    --arw: 83;
    --arh: 72;
    width: ac(83px, 76px);
    flex-shrink: 0;
    transition: width 0.25s ease;
    z-index: 100;
    margin-right: ac(40px, 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;

    &:hover {
      img,
      svg {
        transform: scale(0.94);
        transition: 0.3s;

        .color-change {
          path {
            fill: var(--cl-sky);
          }
        }
      }
    }

    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
      transition: transform 0.25s ease;

      .color-change {
        path {
          transition: all 0.3s ease;
          fill: var(--cl-orange);
        }
      }
    }

    @media (max-width: 1023px) {
      margin-right: auto;
    }
  }

  &__wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .burger {
    display: none;
  }

  &__navbar {
    display: flex;
    flex-grow: 1;

    .menu-list {
      display: flex;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }

    @mixin mobile-menu-end-point {
      max-width: ac(550px, 460px);
    }

    @mixin min-media 1400 {
      margin-bottom: -2px;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /*transition: opacity 0.2s ease;
    opacity: 0;*/

    @mixin mobile-menu-end-point {
      width: auto;
      flex: 1;
      align-items: center;
      max-width: ac(750px, 580px);
      margin-left: auto;

      .simplebar-content-wrapper,
      .simplebar-wrapper,
      .simplebar-mask {
        overflow: visible !important;
      }

      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        grid-column-gap: ac(28px, 20px);

        .menu-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: ac(28px, 20px);
        }

        &:after {
          display: none;
        }
      }
      .simplebar-track.simplebar-horizontal {
        display: none;
      }
    }

    &:not([data-simplebar='init']) {
      display: none;
    }
  }

  .menu__item {
    position: relative;

    &.menu-dropdown {
      padding-right: 12px;
      position: relative;

      @mixin max-sm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }

      @mixin mobile-menu-start-point {
        padding-right: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        .menu__link {
          white-space: nowrap;
        }

        .menu-dropdown__list {
          width: 100% !important;
        }

        @mixin max-sm {
          justify-content: center;
        }
      }

      .menu-dropdown {
        &__arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--cl-black);
          cursor: pointer;
          transition: all 0.3s ease;

          @mixin mobile-menu-end-point {
            position: absolute;
            top: 55%;
            transform: translateY(-50%) scale(1, 1);
            right: 0;
            font-size: 7px;
          }

          @mixin mobile-menu-start-point {
            transform: scale(1, 1);
            font-size: 12px;
            margin-left: 8px;

            &.active {
              transform: scale(1, -1);
            }
          }
        }

        &__list {
          transition: max-height 0.3s ease, opacity 0.3s ease,
            margin-top 0.3s ease;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          width: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          @mixin mobile-menu-end-point {
            padding-left: 10px;
            padding-right: 10px;
            position: absolute;
            bottom: -2px;
            transform: translateY(100%);
            background-color: rgba(28, 28, 28, 0.8);
            z-index: 999;

            .menu__link {
              &:first-child {
                margin-top: 5px !important;
              }

              &:last-child {
                margin-bottom: 5px !important;
              }
            }
          }

          .menu__link {
            white-space: nowrap;
          }

          @mixin max-sm {
            align-items: center;
          }

          @mixin mobile-menu-start-point {
            &.active {
              max-height: 300px;
              opacity: 1;
              margin-top: 25px;
              grid-row-gap: 25px;
            }
          }
        }
      }

      &:hover {
        .menu-dropdown__arrow {
          color: var(--cl-orange);
        }

        @mixin mobile-menu-end-point {
          .menu-dropdown__arrow {
            transform: translateY(-50%) scale(1, -1);
          }

          .menu-dropdown__list {
            max-height: 300px;
            opacity: 1;
          }
        }
      }
    }
  }

  .menu__link {
    position: relative;
    font-size: ac(16px, 14px);
    line-height: normal;
    font-weight: 600;
    letter-spacing: 0.04em;
    transition: all 0.3s ease;
    cursor: pointer;
    font-family: var(--font-main);

    @media (min-width: 1024px) {
      white-space: nowrap;
    }

    &:before {
      position: absolute;
      bottom: 0;
      left: -2px;
      right: -2px;
      height: 0;
      background: var(--cl-lilac-450);
      transition: background-color 0.3s ease, height 0.3s ease,
        opacity 0.3s ease;
      content: '';
      z-index: -1;
      opacity: 0;
    }

    &:hover {
      color: var(--cl-lilac-950);

      &:before {
        height: 45%;
        opacity: 1;
      }
    }
  }

  .menu__categories {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (min-width: 1024px) {
      display: none;
    }
  }

  .menu__categories-title {
    text-transform: uppercase;
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  .menu__categories-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ac(8px, 6px);

    @media (max-width: 551px) {
      .category-btn {
        padding: 9px 13px;
      }
    }
  }

  .btn-icon__count {
    transition: .4s;

    &.is-hidden {
      transform: scale(0);
      opacity: 0;
    }
  }

  &__additional {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding-left: ac(22px, 16px);

    .btn-icon {
      &.hidden {
        @media (min-width: 902px) {
          display: none;
        }
      }

      &.cart-visible-page {
        background-color: var(--cl-white);
        cursor: default;

        i {
          color: var(--cl-lilac-500);
        }

        .btn-icon__count {
          background-color: var(--cl-white);
          border: 1px solid var(--cl-red);

          span {
            color: var(--cl-red);
          }
        }
      }
    }

    @mixin mobile-menu-start-point {
      margin-right: 16px;
    }

    @mixin max-xs {
      gap: 2px;
      margin-right: 10px;
    }
  }

  &__socials {
    margin-top: auto;

    @mixin mobile-menu-end-point {
      display: none !important;
    }

    @mixin max-sm {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 48px;

      @media screen and (max-height: 730px) {
        margin-top: 38px;
      }

      @media screen and (max-height: 640px) {
        margin-top: 24px;
      }
    }
  }

  &__buttons {
    &.buttons {
      @mixin mobile-menu-end-point {
        display: none;
      }

      @mixin sm {
        .btn-second {
          padding-left: 19px;
          padding-right: 19px;
        }
      }

      @mixin max-sm {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      @mixin media 551 {
        .btn-second {
          width: 100%;
        }
      }
    }
  }

  &__categories {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(14px, 8px);
    margin-right: auto;
    cursor: pointer;

    @media (max-width: 1023px) {
      display: none;
    }

    &.active {
      .header__categories-btn {
        background-color: var(--cl-white) !important;

        i {
          color: var(--cl-lilac-500) !important;
        }

        i.icon-close {
          opacity: 1;
        }
      }
    }

    &:not(.active) {
      .header__categories-btn {
        i.icon-burger {
          opacity: 1;
        }
      }

      &:hover {
        .header__categories-btn {
          background-color: var(--cl-lilac-450);

          i {
            color: var(--cl-lilac-850);
          }
        }

        .header__categories-text {
          color: var(--cl-lilac-500);
        }
      }
    }
  }

  &__categories-text {
    font-weight: 700;
    font-size: ac(18px, 14px);
    letter-spacing: 0.04em;
    cursor: pointer;
    transition: 0.3s;
  }

  &__categories-btn {
    min-width: ac(56px, 48px);

    i {
      opacity: 0;
      transition: 0.3s;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &.btn-icon {
      transition: .4s;

      i {
        font-size: ac(30px, 24px) !important;
      }
    }
  }

  &__language {
    display: flex;
    align-items: center;
    position: relative;
    min-height: ac(27px, 25px);
    margin-left: ac(64px, 30px);
    margin-top: ac(5px, 0px);

    &:hover {
      .header {
        &__language-btn {
          i {
            transform: scaleY(-1);
          }
        }

        &__language-list {
          max-height: 100px;
          opacity: 1;
        }
      }
    }
  }

  &__language-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    grid-column-gap: 4px;

    span {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.04em;
    }

    i {
      color: var(--cl-lilac-500);
      font-size: 8px;
      transition: 0.3s;
    }
  }

  &__language-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 12px;
    border: 1px solid var(--cl-lilac-850);
    background: var(--cl-lilac-450);
    position: absolute;
    left: 0;
    bottom: -4px;
    transform: translateY(100%);
    z-index: 999;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: 0.3s;

    a {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.04em;
      transition: 0.3s ease;

      &:hover {
        color: var(--cl-lilac-500);
      }
    }
  }

  &__burger {
    cursor: pointer;

    &.active {
      background-color: var(--cl-white) !important;

      i {
        color: var(--cl-lilac-500) !important;
      }
    }

    @media (min-width: 1024px) {
      display: none !important;
    }

    i {
      opacity: 0;
      transition: 0.3s;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &:not(.active) {
      i.icon-burger {
        opacity: 1;
      }
    }

    &.active {
      i.icon-close {
        opacity: 1;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@mixin mobile-menu-start-point {
  .header:not(.header-mobile) {
    .burger {
      display: flex;
    }

    .logo {
      border: none;
    }

    .header__navbar-cont {
      display: flex;
      flex-direction: column;

      height: 100%;
      margin: 0 auto;
      max-width: 1280px;
      width: perc(1280);

      @mixin max-lg {
        width: 89.33%;
      }
    }

    .header__navbar {
      position: fixed;
      right: 0;
      top: var(--top-nav);
      width: 50%;
      min-width: 320px;
      padding-top: 60px;
      background: var(--cl-lilac-50);
      padding-right: 40px;
      padding-left: 40px;
      padding-bottom: 40px;
      transition: transform 0.3s ease-out;
      overflow: hidden;
      flex-direction: column;
      height: calc((var(--vh, 1vh) * 100) - var(--top-nav));
      z-index: -1;

      @media screen and (max-height: 740px) {
        padding-top: 40px;
      }

      @media screen and (max-height: 640px) {
        padding-top: 32px;
        padding-bottom: 32px;
      }

      @mixin max-md {
        padding-right: 32px;
        padding-left: 32px;
      }

      @mixin max-sm {
        width: 100%;
        padding-right: 24px;
        padding-left: 24px;
      }

      @media (max-width: 551px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      .menu {
        max-height: 100%;
        margin-bottom: 10px;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0;

        align-items: flex-start;
        align-self: center;
        position: relative;
        z-index: 2;

        overflow-x: hidden;
        overflow-y: auto;

        .simplebar-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;

          .menu-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        .simplebar-track.simplebar-vertical {
          width: 6px;
          background: var(--cl-white);
          border-radius: 0;

          .simplebar-scrollbar {
            background: var(--cl-primary);
            border-radius: 0;

            &:before {
              content: none;
            }
          }
        }

        &__item.hidden {
          display: block;

          &.first-hidden {
            margin-top: 28px;

            @media screen and (max-height: 730px) {
              margin-top: 10px;
            }

            @media screen and (max-height: 640px) {
              margin-top: 0;
            }
          }

          .menu__link {
            position: relative;

            &:before {
              left: 0;
              bottom: 0;
              right: 0;
              height: 1px;
              width: 100%;
              opacity: 1;
              background: var(--cl-green);
              content: '';
              position: absolute;
              display: block;
            }
          }
        }
      }

      .menu__link {
        font-size: ac(24px, 20px);
        font-weight: 600;
        padding: 0;
        color: var(--cl-lilac-850);

        &:hover {
          color: var(--cl-lilac-500);
        }

        &:before {
          display: none;
        }
      }

      .menu__item {
        padding-bottom: 18px;

        @mixin media-height 740 {
          padding-bottom: 14px;
        }

        @mixin media-height 700 {
          padding-bottom: 10px;
        }
      }
    }

    /*&.active {
      padding: 14px *;
      .logo {
        width: 215px;
      }
    }*/
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger {
  background: var(--cl-orange);
  position: relative;
  z-index: 100;
  clip-path: inset(0 0 0 0 round 50%);
  overflow: hidden;
  transition: background-color 0.3s ease;
  display: none !important;

  /*&:before {
    z-index: 1;
    inset: 0;
    content: '';
    position: absolute;
    background: var(--gradient);
    transition: opacity 0.3s ease;
    opacity: 0;
  }*/

  .burger-icon {
    cursor: pointer;
    transition: transform 400ms, background-color 0.3s ease;
    user-select: none;
    width: 48px !important;
    min-width: 48px !important;
    height: 48px !important;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    opacity: 1;
    transition: opacity 0.3s ease, transform 0.4s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(0deg);
    font-size: 20px;
    color: var(--cl-white);
  }

  &.active {
    /*&:before {
      opacity: 1;
    }*/

    i {
      opacity: 0;
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
    }

    .burger-icon {
      transform: rotate(45deg);
    }

    .top {
      stroke-dashoffset: -68px;
    }

    .bottom {
      stroke-dashoffset: -68px;
    }

    .line {
      stroke: var(--cl-white);
      opacity: 1;
    }
  }

  .line {
    fill: none;
    transition: stroke 0.3s ease, stroke-dasharray 400ms,
      stroke-dashoffset 400ms, opacity 0.3s ease;
    stroke: var(--cl-white);
    stroke-width: 3.5;
    stroke-linecap: round;
    opacity: 0;
  }

  .top {
    stroke-dasharray: 40 121;
  }

  .bottom {
    stroke-dasharray: 40 121;
  }

  &:hover {
    background-color: var(--cl-light-orange);

    .line {
      stroke: var(--cl-light-bg);
    }
  }

  &.active {
    &:hover {
      .line {
        stroke: var(--cl-light-bg);
      }
    }
  }

  @mixin max-xs {
    .burger-icon {
      width: 44px !important;
      min-width: 44px !important;
      height: 44px !important;
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}
/* end of HEADER COMPONENTS */
