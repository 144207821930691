.payment-info-section {
  padding-top: ac(64px, 30px);
  padding-bottom: ac(64px, 30px);
  background: var(--cl-lilac-50);
  overflow: hidden;

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(24px, 16px);
    grid-row-gap: ac(38px, 16px);

    @media (max-width: 1024px) {
      grid-column-gap: 16px;

      @media (min-width: 1020px) {
        grid-column-gap: 8px;
      }
    }

    @media (max-width: 851px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(14px, 10px);
    width: 100%;

    span.headline-3 {
      --size: ac(56px, 48px);
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      background: var(--cl-lilac-450);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: var(--cl-lilac-500);
    }

    h2 {
      max-width: calc(100% - ac(70px, 58px));
      font-family: var(--font-second);
    }

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  &__content {
    border-radius: ac(12px, 8px);
    border: 1px solid #dfdfe3;
    padding: ac(31px, 20px) ac(39px, 16px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--cl-white);
    flex: 1;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    @media (max-width: 1024px) {
      padding: 20px 16px;
    }
  }

  &__content-title {
    width: 100%;
    border-bottom: 1px solid var(--cl-lilac-450);
    &.subtitle-3 {
      padding-bottom: ac(20px, 18px);
      font-weight: 500;

      &:not(:last-child) {
        margin-bottom: ac(20px, 18px);
      }
    }
    &.subtitle-4 {
      padding-bottom: 16px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__content-text {
    font-feature-settings: 'pnum' on, 'lnum' on;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &.body-text-3 {
      color: var(--cl-dark-gray);
      padding-bottom: 16px;
      border-bottom: 1px solid var(--cl-lilac-450);
    }
  }

  &__content-subtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.4;
    color: var(--cl-lilac-500);

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &.subtitle-4 {
      font-family: var(--font-main);
      font-size: ac(20px, 18px);
      line-height: 1.4;
      font-weight: 700;

      &:not(:last-child) {
        margin-bottom: ac(21px, 16px);
      }
    }
  }

  &__content-contact {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ac(20px, 16px);
  }

  &__contact-link,
  &__location-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ac(12px, 10px);

    &:hover {
      .payment-info-section__contact-icon,
      .payment-info-section__location-icon {
        i {
          color: var(--cl-lilac-950);
        }

        svg {
          path {
            fill: var(--cl-lilac-950);
          }
        }
      }

      .payment-info-section__contact-text {
        color: var(--cl-lilac-950);

        &:before {
          height: 45%;
        }
      }

      .payment-info-section__location-text {
        color: var(--cl-lilac-500);
      }
    }
  }

  &__contact-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__contact-text,
  &__location-text {
    font-size: ac(18px, 16px);
    line-height: 1.36;
    font-weight: 500;
    font-family: var(--font-main);
    transition: all 0.3s ease;
    max-width: calc(100% - ac(32px, 28px));
    position: relative;
    z-index: 1;
    white-space: nowrap;
    font-feature-settings: 'pnum' on, 'lnum' on;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--cl-lilac-450);
      transition: background-color 0.3s ease, height 0.3s ease;
      content: '';
      z-index: -1;
    }
  }

  &__location-text {
    white-space: normal;
    &:before {
      display: none;
    }
  }

  &__contact-icon,
  &__location-icon {
    i {
      font-size: ac(20px, 18px);
      color: var(--cl-lilac-500);
      transition: 0.3s;
    }

    svg {
      path {
        transition: 0.3s;
        color: var(--cl-lilac-500);
      }
    }
  }
}
