.choose-us-section {
  padding-top: ac(77px, 40px);
  padding-bottom: ac(80px, 40px);
  position: relative;
  overflow: hidden;

  &__bg {
    --arw: 1440;
    --arh: 1980;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-width: 1000px;
  }

  &__wrap {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    .headline-2 {
      text-transform: uppercase;
      line-height: 1.08;
    }

    &:not(:last-child) {
      margin-bottom: ac(54px, 24px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    .swiper {
      width: 100%;
      max-width: 100%;
      overflow: visible;
    }

    @media (min-width: 1024px) {
      .swiper {
        .swiper-wrapper {
          display: flex;
          align-items: stretch;
          justify-content: center;
          flex-wrap: wrap;
          grid-column-gap: ac(24px, 16px);
          grid-row-gap: ac(24px, 20px);

          .choose-us-section__item {
            width: calc(33% - (ac(24px, 16px) / 2));

            @media (max-width: 1400px) {
              width: calc(32% - (ac(24px, 16px) / 2));
            }
          }
        }
      }

      .swiper-buttons {
        display: none;
      }
    }

    @media (max-width: 1023px) {
      .swiper-buttons {
        .swiper-button {
          margin-top: ac(48px, 32px);
        }
      }
    }
  }

  &__item {
    background: var(--cl-white);
    border: 1px solid var(--cl-lilac-450);
    border-radius: ac(20px, 16px);
    padding-top: ac(31px, 24px);
    padding-bottom: ac(31px, 24px);
    padding-left: ac(23px, 20px);
    padding-right: ac(25px, 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
  }

  &__item-image {
    --arw: 1;
    --arh: 1;
    width: 23.26%;

    img {
      object-fit: contain;
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 72.09%;
  }

  &__item-text {
    font-weight: 500;
  }
}
