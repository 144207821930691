.our-contacts-section {
  padding-top: ac(108px, 90px);
  &__wrap {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    @media (max-width: 901px) {
      flex-direction: column;
      grid-row-gap: 30px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 44.36%;
    padding-top: ac(68px, 30px);
    padding-bottom: ac(68px, 30px);

    @media (max-width: 901px) {
      width: 100%;
      padding-top: 40px;
      padding-bottom: 0;
    }
    &-block {
      margin-bottom: ac(16px, 12px);
      padding-bottom: ac(20px, 14px);
      border-bottom: 1px solid var(--cl-lilac-450);
      width: 100%;
      &:last-child {
        margin-bottom: 0;
        border: none;
        padding: 0;
      }
    }
    &-title {
      font-size: 22px;
      font-weight: 600;
      color: var(--cl-lilac-850);
      margin-bottom: ac(16px, 12px);
    }
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: ac(16px, 12px);
      &:last-child {
        margin-bottom: 0;
      }
      i {
        font-size: ac(32px, 22px);
        color: var(--cl-lilac-500);
        margin-right: ac(12px, 6px);
      }

      svg {
        width: ac(33px, 22px);
        min-width: ac(33px, 22px);
        height: ac(33px, 22px);
        margin-right: ac(11px, 6px);
      }

      a {
        &.payment-info-section__contact-link {
        }

        .payment-info-section__contact-text {
          font-size: ac(22px, 16px);
          font-weight: 500;
          color: var(--cl-lilac-850);
        }
        .payment-info-section__contact-icon {
          i {
            font-size: ac(32px, 22px);
            margin-right: 0;
            color: var(--cl-lilac-500);
          }
        }
      }
    }
    &-text {
      font-size: ac(22px, 16px);
      font-weight: 500;
      color: var(--cl-lilac-850);
    }
  }
  /* main title */
  &__title {
    margin-bottom: ac(32px, 22px);
  }

  /* map */
  &__map {
    width: 49.02%;
    min-height: ac(508px, 300px);
    position: relative;
    filter: grayscale(100%);
    transition: filter 0.3s;

    @media (min-width: 1300px) {
      margin-right: -5px;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: ac(-108px, -20px);
      max-width: unset;
      max-height: 100%;

      @media (min-width: 1441px) {
        right: calc(((100vw - 1224px) / 2) * -1);
        width: calc(100% + ((100vw - 1224px) / 2));
      }
      @media (max-width: 1441px) {
        width: ac(703px, 440px, 901);
      }
      @media (max-width: 901px) {
        width: 100%;
      }
    }

    @media (max-width: 901px) {
      width: 100%;
      min-height: ac(490px, 420px, 500, 901);
    }

    @media (max-width: 451px) {
      margin-left: -20px;
      width: calc(100% + 40px);

      iframe {
        right: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-position: right;
      }
    }

    &:hover {
      filter: grayscale(0%);
    }
  }
}
