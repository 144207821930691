.contact-section {
  padding-top: ac(80px, 30px);
  padding-bottom: ac(80px, 30px);
  background: var(--cl-lilac-500);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 5;

    @media (max-width: 851px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: ac(40px, 30px);
    }
  }

  &__bg {
    --arw: 1440;
    --arh: 2589;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-width: 1000px;
  }

  &__image {
    width: 47.39%;
    border-radius: ac(40px, 20px);
    overflow: hidden;
    max-width: 538px;

    @media (max-width: 851px) {
      width: 100%;
      max-width: 420px;
    }

    @media (max-width: 551px) {
      --arw: 1.2;

      img {
        object-position: bottom;
      }
    }
  }

  &__content {
    color: var(--cl-white);
    width: 48.69%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 851px) {
      width: 100%;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(16px, 20px);
    }
  }

  &__text {
    &.body-text-1 {
      font-weight: 500;
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 22px);
    }
  }

  &__form {
    width: 100%;

    .meta-input,
    .meta-select,
    .meta-upload-file {
      label {
        color: var(--cl-lilac-50);

        span {
          color: inherit;
        }
      }
    }
  }

  &__form-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(16px, 14px);
    grid-row-gap: ac(12px, 14px);

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }

    @media (max-width: 425px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__form-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ac(24px, 20px);

    @media (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .btn {
      height: 48px;
      min-height: 48px;
      padding-left: 27px;
      padding-right: 21px;

      &__text {
        font-size: 16px;
      }
    }
  }

  &__form-info {
    flex: 1;
    color: var(--cl-lilac-50);
    font-weight: 600;

    a {
      text-decoration: underline;
      color: var(--cl-white);
      transition: 0.3s;

      &:hover {
        color: var(--cl-lilac-450);
      }
    }
  }
}
