.text-image-oval-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(60px, 40px);
  position: relative;
  z-index: 10;

  &__wrap {
    position: relative;
    z-index: 10;
    background: var(--cl-lilac-500);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 1000px;

    @media (max-width: 690px) {
      flex-direction: column-reverse;
      align-items: center;
      grid-row-gap: 20px;
      border-radius: 12px;
      padding: 24px 16px;
    }
  }

  &__image-container {
    position: relative;
    width: 37.91%;
    border-radius: 50%;
    overflow: hidden;

    @media (max-width: 1250px) {
      width: 44%;
    }

    @media (max-width: 690px) {
      width: 100%;
      max-width: 350px;
      display: none;
    }
  }

  &__image {
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    pointer-events: none;
  }

  &__decor {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(20deg) translateZ(0);
    opacity: 0;
    width: 86.67%;
    pointer-events: none;
    transition: 1.5s ease-in;

    &.animated {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(0deg) translateZ(0) !important;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--cl-white);
    padding-top: 20px;
    padding-bottom: 20px;
    width: 45.75%;
    margin-right: ac(120px, 40px);

    @media (max-width: 1250px) {
      margin-right: 40px;
    }

    @media (max-width: 1050px) {
      width: 48%;
    }

    @media (max-width: 801px) {
      margin-right: 0;
      width: 50%;
    }

    @media (max-width: 690px) {
      width: 100%;
      padding: 0;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  @media (max-width: 951px) {
    padding-top: 40px;
    background: var(--cl-lilac-500);
    border-radius: 20px;

    + .reviews-section {
      padding-top: 80px;
      margin-top: -40px;
    }
  }

  @media (max-width: 690px) {
    background: transparent;
    border-radius: 0;
  }

  @media (max-width: 551px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  + .reviews-section {
    @media (min-width: 952px) {
      padding-top: ac(212px, 200px);
      margin-top: ac(-193px, -180px);
    }
  }
}
