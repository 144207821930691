.product-section {
  padding-top: ac(108px, 100px);
  padding-bottom: ac(80px, 35px);
  overflow: hidden;
  position: relative;
  z-index: 20;

  &__wrap {
    padding-top: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__prev-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    gap: 8px;
    transition: 0.3s;

    i {
      font-size: ac(24px, 20px);
      transition: 0.3s;
    }

    &:not(:last-child) {
      margin-bottom: 18px;
    }

    &:hover {
      color: var(--cl-lilac-500);

      i {
        transform: translateX(-8px);
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 32px;
    }

    @media (max-width: 551px) {
      grid-row-gap: 24px;
    }
  }

  &__slider {
    &.thumbs-slider {
      width: 49.02%;

      @media (max-width: 1200px) {
        width: 44%;
      }

      @media (max-width: 1023px) {
        width: 100%;
        max-width: 550px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 47.06%;

    @media (max-width: 1300px) {
      width: 48.5%;
    }

    @media (max-width: 1200px) {
      width: 54%;
    }

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &__categories {
    gap: ac(8px, 6px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
      font-size: ac(14px, 12px);
      font-family: var(--font-main);
      color: var(--cl-lilac-500);
      line-height: 28px;
      letter-spacing: 0.02em;
      font-weight: 700;
      position: relative;
      text-transform: uppercase;

      &:not(:last-child) {
        &:after {
          content: '/';
          display: inline-block;
          margin-left: ac(8px, 6px);
          color: var(--cl-lilac-450);
          font-family: var(--font-second);
          font-weight: 700;
          transform: scaleX(1.2);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__card {
    &.product-card {
      box-shadow: none;
      overflow: visible;
      border: none;
      padding: 0;
      width: 100%;

      &.is-open-counter {
        background: transparent;
      }
    }

    .product-card {
      &__page-title {
        &:not(:last-child) {
          margin-bottom: ac(28px, 18px);
        }
      }

      &__content {
        padding: 0;
      }

      &__wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1100px) {
          @media (min-width: 1024px) {
            flex-direction: column;
            align-items: flex-start;
            grid-row-gap: 16px;

            & > * {
              max-width: 278px;
            }
          }
        }

        @media (max-width: 551px) {
          flex-direction: column;
          align-items: flex-start;
          grid-row-gap: 16px;
        }
      }

      &__select {
        max-width: ac(242px, 220px);

        label {
          margin-bottom: 4px;
          display: flex;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.4;
          color: var(--cl-lilac-500);
          text-transform: none;
        }

        &:not(:last-child) {
          margin-bottom: 0;
        }

        @media (max-width: 551px) {
          max-width: 100%;
          width: 100%;
        }
      }

      &__bottom {
        width: auto;

        @media (max-width: 551px) {
          max-width: 100%;
          width: 100%;
        }
      }

      &__info {
        margin-right: 20px;
      }

      &__info.with-sale {
        padding-left: ac(40px, 20px);

      }

      &__info-price {
        font-family: var(--font-main);
        font-size: ac(26px, 20px);
        line-height: 1.4;
        font-weight: 700;
      }

      &__info-unit {
        font-family: var(--font-main);
        font-size: ac(26px, 20px);
        line-height: 1.4;
        font-weight: 700;
      }

      &__btn-counter {
        .btn {
          width: 100%;
          /*pointer-events: all;*/
          min-height: 48px;
          height: 48px;
          max-width: 191px;
          padding-left: 27px;
          padding-right: 21px;

          .btn__text {
            white-space: nowrap;
            font-size: ac(16px, 15px) !important;
          }
        }

        &:not(.active) {
          .btn {
            opacity: 1;
            pointer-events: all;
          }

          .btn-icon {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__tabs-container {
    padding: ac(6px, 7px) ac(54px, 24px) ac(10px, 14px) ac(31px, 20px);
    width: 100%;
    border-radius: ac(12px, 8px);
    border: 1px solid var(--cl-lilac-450);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 451px) {
      padding-left: 12px;
    }

    @media (max-width: 374px) {
      padding-left: 10px;
    }
  }

  &__tabs-buttons {
    width: calc(100% + ac(46px, 18px));
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--cl-lilac-400);

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ac(14px, 12px) ac(20px, 10px);
    position: relative;
    cursor: pointer;
    font-family: var(--font-main);
    font-size: ac(20px, 16px);
    line-height: 1.4;
    font-weight: 700;
    color: var(--cl-lilac-500);
    transition: 0.3s;

    &:before {
      position: absolute;
      bottom: -2px;
      height: 4px;
      background: var(--cl-lilac-500);
      right: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
      content: '';
      opacity: 0;
      transition: 0.3s;
    }

    &.active {
      color: var(--cl-lilac-850);
      &:before {
        opacity: 1;
      }
    }

    @media (max-width: 340px) {
      font-size: 14px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &__tabs-list {
    width: 100%;
    position: relative;
  }

  &__tab-item {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    top: 0;
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;

    &.active {
      position: relative;
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__tab-item-scroll {
    width: calc(100% + ac(38px, 18px));
    padding-right: ac(38px, 18px);
    max-height: ac(295px, 260px);

    .simplebar-track.simplebar-vertical {
      width: ac(6px, 4px);
      background: var(--cl-lilac-450);
      border-radius: 12px;
      transform: none;
      bottom: 10px;

      .simplebar-scrollbar {
        background: var(--cl-lilac-500);
        border-radius: 12px;

        &:before {
          content: none;
        }
      }
    }

    position: relative;



    &.simplebar-scrollable-y {
      &:after {
        position: absolute;
        left: 0;
        right: ac(6px, 4px);
        bottom: -1px;
        background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 100%
        );
        height: ac(40px, 30px);
        content: '';
      }
    }

    @media (max-width: 640px) {
      max-height: 600px;
    }
  }

  &__tab-item-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    li {
      font-size: 16px;
      font-weight: 500;
      color: var(--cl-lilac-500);

      strong {
        font-weight: 600;
        color: var(--cl-lilac-950);
      }

      &:not(:last-child) {
        margin-bottom: ac(12px, 10px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 16px);
    }
  }

  &__tab-item-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 144%;
    font-feature-settings: 'pnum' on, 'lnum' on;

    p {
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;

      &:not(:last-child) {
        margin-bottom: ac(12px, 10px);
      }
    }

    > * {
      &:last-child {
        margin-bottom: ac(20px, 14px);
      }
    }
  }
}

.thumbs-slider {
  .thumbs-slider-top {
    width: 100%;
    padding-bottom: ac(24px, 12px);
    overflow: hidden;
    border-radius: ac(20px, 8px);
    clip-path: inset(0 0 0 0 round ac(20px, 8px));

    &:not(.swiper-initialized) {
      display: none;
    }

    .swiper-slide {
      position: relative;
      @mixin aspect-ratio 600, 450;
      background: var(--white);
      border-radius: ac(20px, 8px);
      border: 1px solid var(--cl-lilac-450);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .thumbs-slider-bottom {
    padding: 1px;

    &:not(.swiper-initialized) {
      display: none;
    }

    &__photo,
    &__video {
      position: relative;
    }

    &__photo {
      width: 100%;
      @mixin aspect-ratio 184, 138;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      --size: ac(52px, 44px);
      width: var(--size);
      height: var(--size);
      border: 1px solid var(--cl-lilac-450);
      background: var(--cl-lilac-850);
      transition: 0.3s;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      opacity: 0;
      z-index: 5;
      position: absolute;
      border-radius: 50%;

      i {
        font-size: ac(17px, 14px);
        color: var(--cl-white);
      }
    }

    .swiper-slide {
      border-radius: ac(12px, 8px);
      overflow: hidden;
      cursor: pointer;
      background: var(--white);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: transform 0.2s ease;
      }

      &::after {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: transparent;
        border-radius: ac(12px, 8px);
        border: 4px solid transparent;
        transition: 0.3s;
      }

      &.swiper-slide-thumb-active {
        &::after {
          border: 4px solid var(--cl-lilac-500);
        }
      }

      &:hover {
        &:hover {
          .thumbs-slider-bottom__icon {
            opacity: 1;
          }

          &:after {
            background: rgba(107, 103, 167, 0.3);
            border: 4px solid var(--cl-lilac-500);
          }
        }
      }
    }
  }
}
