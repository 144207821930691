.join-us-section {
  padding-top: ac(64px, 36px);
  padding-bottom: ac(40px, 36px);
  position: relative;
  &__decorate {
    position: absolute;
    left: 0;
    bottom: -2px;
    z-index: 0;

    @media (max-width: 551px) {
      bottom: 300px;
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 3;
    @media (max-width: 940px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__content {
    max-width: ac(472px, 320px);
    @media (max-width: 940px) {
      max-width: 680px;
      margin-bottom: ac(48px, 32px, 500, 940);
    }

    .btn {
      height: 48px;
      min-height: 48px;
      padding-left: 27px;
      padding-right: 21px;

      &__text {
        font-size: 16px;
      }
    }
  }
  &__title {
    margin-bottom: ac(16px, 14px);
  }
  &__descr {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: ac(40px, 24px);
  }
  &__link {
    max-width: 284px;
  }

  &__slider {
    position: relative;
    border-radius: 500px;
    overflow: visible;
    @media (max-width: 940px) {
      width: 100%;
    }
    .swiper {
      width: ac(600px, 520px, 940);
      border-radius: 500px;
      border: ac(8px, 6px) solid var(--cl-lilac-500);
      overflow: hidden;
      @media (max-width: 940px) {
        width: 100%;
      }
    }
    &-item {
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .swiper-buttons {
      margin-top: ac(-32px, -28px);
    }
  }
}
