.benefits-section {
  padding-top: ac(55px, 30px);
  padding-bottom: ac(40px, 20px);
  overflow: visible;

  &__decors {
    position: absolute;
    left: 0;
    right: 0;
    top: ac(-136px, -50px);
    bottom: ac(-256px, -50px);
    overflow: hidden;

    &[data-view] {
      .benefits-section {
        &__decor {
          transition: 0.6s;
          opacity: 0;

          &:nth-child(1) {
            transform: rotate(-45deg) translateX(50%);
          }

          &:nth-child(2) {
            transform: rotate(135deg) translateX(-50%) translateY(-50%);
          }
        }
      }

      &.animated {
        .benefits-section {
          &__decor {
            opacity: 1;

            &:nth-child(1) {
              transform: rotate(-45deg);
            }

            &:nth-child(2) {
              transform: rotate(135deg);
              transition-delay: 0.3s;
            }
          }
        }
      }
    }
  }

  &__decor {
    &:nth-child(1) {
      --arw: 238.47;
      --arh: 682.36;
      position: absolute;
      width: ac(169px, 100px);
      top: 24px;
      right: ac(27px, 20px);
      transform: rotate(-45deg);
    }

    &:nth-child(2) {
      --arw: 273.3;
      --arh: 379.23;
      position: absolute;
      width: ac(273px, 120px);
      bottom: ac(21px, 0px);
      right: ac(-80px, -40px);
      transform: rotate(135deg);
    }

    &:nth-child(3) {
      --arw: 252.38;
      --arh: 241.33;
      position: absolute;
      width: ac(252px, 90px);
      bottom: ac(126px, 23px);
      left: ac(-64px, -20px);
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 5;
  }

  &__title {
    text-align: center;

    &:not(:last-child) {
      margin-bottom: ac(48px, 24px);
    }
  }

  &__list {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: ac(24px, 20px);
  }

  &__item {
    width: calc(50% - ac(12px, 10px));
    border-radius: ac(24px, 16px);
    border: 1px solid var(--cl-lilac-450);
    padding: ac(30px, 20px) ac(39px, 16px) ac(38px, 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--cl-lilac-50);

    @media (max-width: 1439px) {
      width: calc(49% - ac(12px, 10px));
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__item-title-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    --icon-size: ac(80px, 60px);
    padding-bottom: ac(18px, 14px);
    border-bottom: 1px solid var(--cl-lilac-450);
    gap: ac(16px, 10px);

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  &__item-icon {
    width: var(--icon-size);
  }

  &__item-title {
    max-width: calc(100% - var(--icon-size) - ac(16px, 10px));

    &.headline-6 {
      letter-spacing: 0.01em;
      font-family: var(--font-second);
    }
  }

  &__item-text {
    &.body-text-2 {
      font-weight: 500;

      &[data-simplebar] {
        max-height: calc((ac(18px, 16px) * 1.4) * 8);
      }
    }
  }

  @media (max-width: 951px) {
    padding-bottom: 40px;
  }
}
