@import url('https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

/*@font-face {
  font-family: 'NAMU-1850';
  src: url('../../assets/fonts/NAMU-1850.woff2') format('woff2'),
    url('../../assets/fonts/NAMU-1850.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}*/

:root {
  --cl-black: #242426;

  --cl-black-rgb: 0 0 0;

  --cl-black-regular: #363638;

  --cl-primary: #31306e;

  --cl-primary-rgb: 236 97 42;

  --cl-white: #ffffff;
  --cl-white-rgb: 255 255 255;

  --cl-lilac-950: #29283e;

  --cl-lilac-850: #31306e;

  --cl-lilac-500: #6b67a7;

  --cl-lilac-450: #c5c3e3;

  --cl-lilac-400: #b2b0d7;

  --cl-lilac-50: #f3f4fa;

  --cl-red: #e52139;

  --cl-grey-2: #dfdfe3;

  /*Seafood*/

  --cl-light-bg: #f5fcff;

  --cl-horizon-bg: #f3fafb;

  --cl-gray-line: #dfdfe3;

  --cl-light-gray: #d0d1d3;

  --cl-mid-gray: #888996;

  --cl-dark-gray: #484851;

  --cl-darked-gray: #363638;

  --cl-black-gray: #54545f;

  --cl-light-orange: #f58f45;

  --cl-orange: #e36b40;

  --cl-teal: #009e94;

  --cl-sky: #1799ba;

  --cl-amber: #f7ad40;

  --cl-green: #229f5b;

  --cl-light-beige: #fef5e8;

  --font-main: 'Raleway', sans-serif;
  --font-second: 'Bona Nova', serif;
  --font-third: 'Manrope', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1023;

  --vh: calc(100vh / 100);

  --cart-width: 160px;

  --font-second-letter-spacing: 0.02em;

  --top-nav: 0;

  --title-decor-color: var(--cl-orange);

  --arw: 1;
  --arh: 1;
  --max-line-num: 3;
}

@define-mixin cart-start-point {
  @media (min-width: 902px) {
    @mixin-content;
  }
}

@define-mixin cart-end-point {
  @media not all and (min-width: 902px) {
    @mixin-content;
  }
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
