.stats-section {
  padding-top: ac(48px, 30px);
  padding-bottom: ac(48px, 30px);
  background: var(--cl-lilac-500);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__list {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    grid-column-gap: ac(48px, 20px);
    grid-row-gap: ac(20px, 14px);
    flex-wrap: wrap;

    @media (min-width: 1400px) {
      margin-left: -40px;
      width: calc(100% + 80px);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: ac(40px, 16px) ac(40px, 20px) ac(20px, 10px);
    position: relative;
    color: var(--cl-white);
    width: calc(33% - ac(48px, 20px));

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: calc((ac(48px, 20px) / 2) * -1);
      transform: translateX(50%);
      background: var(--cl-lilac-400);
      width: 1px;
    }

    &:not(:last-child) {
      &:before {
        content: '';
      }
    }

    @media (max-width: 1400px) {
      width: calc(32% - (ac(48px, 20px) / 2));
    }

    @media (max-width: 851px) {
      width: calc(49% - (ac(48px, 20px) / 2));
    }

    @media (max-width: 551px) {
      width: 100%;
      padding-bottom: 0;
      padding-top: 10px;

      &:first-child {
        padding-top: 0;
      }

      &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid var(--cl-lilac-400);

        &:before {
          display: none;
        }
      }
    }
  }

  &__item-count {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      &:not(.count-up) {
        color: var(--cl-lilac-450);
        margin-left: ac(8px, 4px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 16px);
    }
  }

  &__item-text {
    text-align: center;

    &.headline-6 {
      font-family: var(--font-second);
    }
  }
}
