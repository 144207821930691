.recipes-card {
  background: var(--white);
  border: 1px solid var(--cl-gray-line);
  border-radius: ac(16px, 20px);
  height: auto;
  overflow: hidden;
  /*box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);*/
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  transition: box-shadow 0.3s ease;

  &__image {
    @mixin image-aspect-ratio 376, 251;
    width: 100%;

    &:after {
      opacity: 0;
      transition: opacity 0.3s ease;
      background: var(--cl-black);
      inset: 0;
      content: '';
      position: absolute;
    }

    &:hover {
      &:after {
        opacity: 0.3;
      }

      .recipes-card__image-icon {
        opacity: 1;
      }
    }
  }

  &__image-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: var(--cl-white);
    font-size: 45px;
    transition: opacity 0.3s ease;
    z-index: 1;
    opacity: 0;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(24px, 20px) ac(24px, 20px) ac(24px, 20px);
  }

  &__categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: ac(8px, 4px);

    &:not(:last-child) {
      margin-bottom: ac(16px, 12px);
    }
  }

  &__category {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid var(--cl-light-gray);
    padding: ac(7px, 6px) ac(7px, 5px) ac(5px, 4px);
    font-size: 12px;
    font-weight: 600;
    line-height: 1.2;
    font-family: var(--font-main);
    color: var(--cl-sky);
    border-radius: 4px;
    transition: border-color 0.3s ease, color 0.3s ease;
  }

  &__title {
    @mixin max-line-length 4;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all 0.3s ease;

    &:not(:last-child) {
      margin-bottom: ac(14px, 8px);
    }
  }

  &__buttons {
    width: 100%;
    justify-content: flex-end;
    margin-top: auto;
    padding-right: ac(0px, 4px);
  }

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);

    .recipes-card__category {
      border-color: var(--cl-light-gray);
      color: var(--cl-black);
    }

    .recipes-card__title {
      color: var(--cl-orange);
      text-decoration-color: var(--cl-orange);
    }
  }
}
