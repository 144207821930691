.internal-first-section {
  padding-top: ac(108px, 90px);
  background: var(--cl-white);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    /*min-height: calc(100vh - 326px);*/
    position: relative;
    z-index: 2;

    @media (max-width: 901px) {
      flex-direction: column;
      grid-row-gap: 30px;
    }

    /*@media (min-height: 1090px) {
      min-height: 800px;
    }*/
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 44.36%;
    padding-top: ac(80px, 40px);
    padding-bottom: ac(80px, 40px);

    @media (max-width: 901px) {
      width: 100%;
      padding-top: 50px;
      padding-bottom: 10px;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(16px, 20px);
    }
  }

  &__text {
    font-weight: 500;
    font-feature-settings: 'pnum' on, 'lnum' on;

    &.with-600 {
      @media (min-width: 1430px) {
        min-width: 600px;
      }

    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__list-links {
    padding-top: ac(16px, 0px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(8px, 6px);
  }

  &__list-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 8px;
    min-height: ac(48px, 26px);

    span {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      transition: 0.3s;

      @media (max-width: 1300px) {
        letter-spacing: 0.02em;
      }

      @media (max-width: 360px) {
        max-width: 225px;
      }
    }

    i {
      font-size: ac(24px, 20px);
      transition: 0.3s;
    }

    &.with-mob {
      @media (min-width: 769px) {
        display: none;
      }
    }

    &.with-desk {
      @media (max-width: 768px) {
        display: none;
      }
    }

    &:hover {
      span {
        color: var(--cl-lilac-500);
      }

      i {
        transform: rotate(-90deg);
        color: var(--cl-lilac-500);
      }
    }
  }

  &__image {
    width: 49.02%;
    min-height: ac(508px, 280px);
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: ac(-108px, -20px);
      max-width: unset;
      max-height: 100%;
      object-fit: cover;

      @media (min-width: 1441px) {
        right: calc(((100vw - 1224px) / 2) * -1);
        width: calc(100% + ((100vw - 1224px) / 2));
      }
    }

    @media (max-width: 901px) {
      width: 100%;
    }

    @media (max-width: 451px) {
      margin-left: -20px;
      width: calc(100% + 40px);

      img {
        right: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-position: right;

        @media (max-width: 360px) {
          object-position: center;
        }
      }
    }
  }

  &.payment-page {
    .internal-first-section {
      &__image {
        min-height: ac(440px, 300px);
      }

      &__wrap {
        @media (min-width: 2000px) {
          min-height: 560px;
        }

        @media (min-width: 2500px) {
          min-height: 650px;
        }
      }
    }
  }

  &.cooperation-page {
    .internal-first-section {
      &__image {
        img {
          @media (max-width: 1370px) {
            @media (min-width: 1023px) {
              object-position: right;
              width: 50vw;
              height: 100%;
            }
          }
        }
      }
    }
  }

  + .stats-section {
    @media (max-width: 901px) {
      @media (min-width: 452px) {
        margin-top: 40px;
      }
    }
  }
}
