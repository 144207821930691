.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--cl-white);
  border-radius: 12px;
  border: 2px solid var(--cl-lilac-50);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16),
    0px 1px 5px 1px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.3s, background-color 0.3s;
  height: auto;
  overflow: visible;
  position: relative;

  &__link {
    position: absolute;
    inset: 0;
    z-index: 1;
    border-radius: inherit;
  }

  &__image-container {
    width: 100%;
    position: relative;
    border-bottom: 2px solid var(--cl-lilac-400);
    overflow: hidden;
  }

  &__list {
    --diference: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    min-width: 100%;
    padding: 2px ac(14px, 12px);
    background: rgba(49, 48, 110, 0.7);
    gap: ac(8px, 6px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    transition: 0.4s;

    li {
      font-size: 12px;
      font-family: var(--font-main);
      color: var(--cl-white);
      line-height: 28px;
      letter-spacing: 0.02em;
      font-weight: 700;
      position: relative;
      text-transform: uppercase;
      white-space: nowrap;

      &:not(:last-child) {
        &:after {
          content: '/';
          display: inline-block;
          margin-left: ac(8px, 6px);
          color: var(--cl-lilac-450);
          font-family: var(--font-second);
          font-weight: 700;
          transform: scaleX(1.2);
        }
      }
    }
  }

  &:hover {
    .product-card__list {
      &.is-hovered {
        margin-left: calc(-1 * (var(--diference) - 1px));
      }
    }
  }

  &__image {
    border-radius: 11px 11px 0 0;
    overflow: hidden;
    --arw: 288;
    --arh: 262;

    @media (max-width: 551px) {
      --arh: 252;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    padding: ac(14px, 16px) ac(19px, 18px) 16px;
    z-index: 2;
    pointer-events: none;
    position: relative;

    @media (max-width: 1320px) {
      @media (min-width: 1279px) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }

  &__title {
    &.subtitle-2 {
      font-size: ac(22px, 20px);
      text-transform: none;
      line-height: 1.32;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__select {
    width: 100%;
    pointer-events: all;
    position: relative;
    z-index: 20;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    label {
      text-transform: uppercase;
      letter-spacing: 0.04em;
      margin-bottom: ac(1px, 2px);
      display: none;
    }

    .choices {
      &__item {
        &.choices__item--selectable {
          font-size: 16px;
        }
      }
    }
  }

  &__is-out-stock {
    width: 100%;
    border: 1px solid var(--cl-lilac-450);
    border-radius: 6px;
    font-size: 12px;
    line-height: 1.32;
    font-weight: 600;
    letter-spacing: 0.04em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-family: var(--font-main);
    color: #e52139;
    padding: 5px ac(13px, 12px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 44px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__bottom {
    margin-top: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 48px;
  }

  &__info {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: calc(100% - 192px);
    gap: 2px;

    &.with-sale {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      flex-wrap: wrap;
    }
  }



  &__info-price {
    font-size: ac(24px, 22px);
    font-weight: 500;
    line-height: normal;
    font-feature-settings: 'pnum' on, 'lnum' on;

    @media (max-width: 1368px) {
      font-size: 22px;
    }

    @media (max-width: 1320px) {
      @media (min-width: 1279px) {
        font-size: 18px;
      }
    }
  }

  &__info-unit {
    font-size: ac(24px, 22px);
    font-weight: 500;
    line-height: normal;
    color: var(--cl-lilac-500);

    @media (max-width: 1368px) {
      font-size: 22px;
    }

    @media (max-width: 1320px) {
      @media (min-width: 1279px) {
        font-size: 18px;
      }
    }
  }

  &__info-old {
    font-weight: 500;
    line-height: 18px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: 13px;
    color: var(--cl-lilac-500);
    text-decoration-line: line-through;
    white-space: nowrap;
  }

  &__info-sale {
    width: 100%;
    font-size: ac(24px, 22px);
    font-weight: 700;
    line-height: 1;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--cl-red);

    @media (max-width: 1368px) {
      font-size: 22px;
    }

    @media (max-width: 1320px) {
      @media (min-width: 1279px) {
        font-size: 18px;
      }
    }
  }

  &__info-text {
    font-size: 16px;
    font-weight: 800;
    line-height: normal;
    color: var(--cl-lilac-500);
    margin-left: ac(5px, 4px);
    text-transform: uppercase;
    white-space: nowrap;

    span {
      font-size: ac(22px, 22px);
      font-weight: 800;
      line-height: normal;
      font-family: var(--font-second);
      color: var(--cl-lilac-450);
    }
  }

  &__btn-counter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 187px;
    position: relative;

    .counter {
      display: none;
      pointer-events: all;
      min-height: 40px;
      height: 40px;
      max-width: 128px;
    }

    .btn-icon {
      --size: 48px;
      width: var(--size);
      height: var(--size);
      pointer-events: all;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08),
        0px 1px 3px 1px rgba(0, 0, 0, 0.12);
    }

    .btn {
      width: 100%;
      /*pointer-events: all;*/
      min-height: 40px;
      height: 40px;
      max-width: 128px;
      padding-left: 14px;
      padding-right: 14px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 2;
      opacity: 0;
      transition: 0.3s;
      pointer-events: none;

      .btn__text {
        white-space: nowrap;
        font-size: ac(16px, 15px) !important;
      }
    }

    &.active {
      .btn {
        display: none;
      }

      .btn-icon {
        display: none;
      }

      .counter {
        display: flex;
      }
    }
  }

  &:hover {
    box-shadow: 0px 4px 24px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.16);

    .product-card {
      &__btn-counter {
        &:not(.active) {
          .btn {
            opacity: 1;
            pointer-events: all;
          }

          .btn-icon {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .product-card {
      &__btn-counter {
        &:not(.active) {
          .btn {
            opacity: 1;
            pointer-events: all;
          }

          .btn-icon {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  }

  &.is-open-counter {
    background-color: var(--cl-lilac-50);
  }

  &.is-out-stock {
    .product-card__btn-counter {
      pointer-events: none !important;

      .btn-icon.btn-icon-light {
        background-color: #f7f7f7;
        border-color: #e3e3e3;
        pointer-events: none;

        i {
          color: #878787;
        }
      }

      .btn-icon,
      .btn {
        pointer-events: none !important;
      }
    }

    &:hover {
      .product-card {
        &__btn-counter {
          &:not(.active) {
            .btn {
              opacity: 0;
            }

            .btn-icon {
              opacity: 1;
            }
          }
        }
      }
    }

    @media (max-width: 1024px) {
      .product-card {
        &__btn-counter {
          &:not(.active) {
            .btn {
              opacity: 0;
            }

            .btn-icon {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
