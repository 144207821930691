.reviews-section {
  padding-top: ac(80px, 30px);
  padding-bottom: ac(82px, 30px);
  position: relative;
  overflow: hidden;

  &__bg {
    --arw: 1440;
    --arh: 1980;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-width: 800px;
  }

  &__wrap {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    width: 100%;
    max-width: ac(650px, 420px);
    text-align: center;

    &.headline-3 {
      line-height: 140%;
      letter-spacing: 0.01em;
    }

    &:not(:last-child) {
      margin-bottom: ac(48px, 24px);
    }
  }

  &__slider {
    width: 100%;

    .swiper {
      width: 100%;
      max-width: ac(1000px, 900px);
    }

    @media (min-width: 1181px) {
      .swiper-buttons {
        position: absolute;
        top: 63.5%;
        transform: translateY(-50%);
        left: ac(0px, -100px);
        right: ac(0px, -100px);
        width: auto;
        justify-content: space-between;
        pointer-events: none;

        .swiper-button {
          margin-top: 0;
          pointer-events: all;
        }
      }
    }

    @media (max-width: 1180px) {
      .swiper-button {
        margin-top: ac(40px, 32px);
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(31px, 20px) ac(31px, 16px);
    border-radius: ac(24px, 16px);
    border: 1px solid var(--cl-grey-2);
    background: var(--cl-white);
    height: auto;
  }

  &__item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(32px, 22px);
    color: var(--cl-lilac-500);

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  &__item-text-container {
    width: 100%;
    padding-bottom: ac(10px, 14px);
  }

  &__item-text {
    max-height: ac(260px, 220px);

    &.body-text-2 {
      line-height: 140%;
      p,
      ul {
        &:not(:last-child) {
          margin-bottom: 11px;
        }
      }

      img {
        display: inline;
        margin-left: 4px;
        margin-right: 4px;
        --size: ac(18px, 16px);
        width: var(--size) !important;
        min-width: var(--size) !important;
        height: var(--size) !important;
      }
    }
  }

  &__item-bottom {
    margin-top: auto;
    border-top: 1px solid var(--cl-grey-2);
    padding-top: ac(20px, 18px);
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__item-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: calc(100% - 120px);
    width: 100%;
  }

  &__item-title {
    text-transform: none;
    font-family: var(--font-second);
    font-feature-settings: 'pnum' on, 'lnum' on;
    letter-spacing: 0.01em;

    &:not(:last-child) {
      margin-bottom: ac(12px, 10px);
    }
  }

  &__item-subtitle {
    text-transform: uppercase;
    color: var(--cl-lilac-500);
    font-size: 16px;
  }

  &__item-socials {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ac(12px, 10px);
  }

  &__item-social {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--cl-grey-2);
    background: transparent;
    --size: ac(56px, 48px);
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    transition: 0.3s;

    i {
      font-size: ac(22px, 20px);
      color: var(--cl-lilac-500);
      transition: 0.3s;
    }

    &:hover {
      background-color: var(--cl-lilac-50);
      border-color: var(--cl-lilac-500);

      i {
        color: var(--cl-lilac-850);
      }
    }
  }
}
