.text-image-section {
  padding-top: ac(80px, 30px);
  padding-bottom: ac(80px, 30px);
  overflow: hidden;
  position: relative;

  &__wrap {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      grid-row-gap: 30px;
    }
  }

  &__image {
    --arw: 600;
    --arh: 400;
    width: 49.02%;
    overflow: hidden;
    border-radius: ac(24px, 20px);

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 46.41%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__title {
    &.headline-3 {
      line-height: 140%;
      letter-spacing: 0;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &.cooperation-page {
    .text-image-section {
      &__image {
        --arw: 600;
        --arh: 450;
      }
    }
  }
}
