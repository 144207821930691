.partner-section {
  padding-top: ac(80px, 30px);
  padding-bottom: ac(80px, 30px);
  background: var(--cl-lilac-500);
  overflow: hidden;
  position: relative;
  z-index: 20;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 5;

    @media (max-width: 951px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: ac(40px, 30px);
    }
  }

  &__bg {
    --arw: 1440;
    --arh: 1461;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &__content {
    color: var(--cl-white);
    width: 42.08%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 951px) {
      width: 100%;
    }
  }

  &__title {
    .headline-3 {
      line-height: 1.6;
    }

    &:not(:last-child) {
      margin-bottom: ac(20px, 20px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(24px, 22px);
    }
  }

  &__form {
    width: 54%;
    border-radius: ac(24px, 20px);
    background: var(--cl-white);
    padding: ac(48px, 24px) ac(40px, 20px);

    .meta-input,
    .meta-select,
    .meta-upload-file {
      label {
        color: var(--cl-lilac-850);

        span {
          color: var(--cl-red);
        }
      }
    }

    .meta-input {
      input {
        background-color: var(--cl-lilac-50);
        box-shadow: none;

        &::placeholder {
          color: var(--cl-lilac-500);
        }
      }
    }

    @media (max-width: 951px) {
      width: 100%;
    }
  }

  &__form-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(16px, 14px);
    grid-row-gap: ac(12px, 14px);

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }

    @media (max-width: 425px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__form-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ac(24px, 20px);

    @media (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .btn {
      height: 48px;
      min-height: 48px;
      padding-left: 27px;
      padding-right: 21px;

      &__text {
        font-size: 16px;
      }
    }
  }

  &__form-info {
    flex: 1;
    color: var(--cl-lilac-500);
    font-weight: 600;

    a {
      text-decoration: underline;
      color: var(--cl-lilac-850);
      transition: 0.3s;

      &:hover {
        color: var(--cl-lilac-450);
      }
    }
  }
}
