.home-first-section {
  padding-top: ac(108px, 90px);
  background: var(--cl-lilac-50);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: calc(100vh - 326px);
    position: relative;
    z-index: 2;

    @media (max-width: 901px) {
      flex-direction: column;
      grid-row-gap: 30px;
    }

    @media (min-height: 1090px) {
      min-height: 800px;
    }
  }

  &__decor {
    --arw: 2141;
    --arh: 1857;
    position: absolute;
    z-index: -1;
    top: ac(37px, 64px);
    right: ac(732px, 300px);
    width: 174.92%;
    max-width: 1857px;
    min-width: 1300px;

    @media (max-width: 551px) {
      right: 40px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 44.36%;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (max-width: 901px) {
      width: 100%;
      padding-top: 60px;
      padding-bottom: 0;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(16px, 20px);
    }
  }

  &__text {
    font-weight: 500;
    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__list {
    width: 49.02%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: ac(24px, 16px);
    min-height: 100%;
    max-height: calc(100vh - 326px);

    position: relative;

    @media (min-height: 1090px) {
      max-height: 800px;
    }

    @media (min-width: 902px) {
      min-height: 490px;
    }

    &:before,
    &:after {
      position: absolute;
      left: -1px;
      right: -1px;
      height: ac(100px, 80px);
      content: '';
      z-index: 5;
      pointer-events: none;
    }

    &:before {
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(243, 244, 250, 0) 0%,
        #f3f4fa 100%
      );
    }

    &:after {
      top: 0;
      background: linear-gradient(
        0deg,
        rgba(243, 244, 250, 0) 0%,
        #f3f4fa 100%
      );
    }

    @media (max-width: 901px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 30px;

      &:before,
      &:after {
        top: -1px;
        bottom: -1px;
        left: auto;
        right: auto;
        height: auto;
        width: 90px;
      }

      &:before {
        /*bottom: auto;*/
        left: 0;
        transform: translateX(-100%);
        background: linear-gradient(
          -90deg,
          rgba(243, 244, 250, 0) 0%,
          #f3f4fa 100%
        );
      }

      &:after {
        /*top: auto;*/
        right: 0;
        transform: translateX(100%);
        background: linear-gradient(
          90deg,
          rgba(243, 244, 250, 0) 0%,
          #f3f4fa 100%
        );
      }
    }
  }

  &__row {
    width: calc(50% - ac(12px, 8px));
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: ac(24px, 16px);

    .js-marquee {
      padding-bottom: ac(25px, 16px);
    }

    @media (max-width: 901px) {
      display: flex;
      flex-direction: row;

      .js-marquee {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 0;
        gap: ac(24px, 16px);
      }

      .js-marquee-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 0;
        gap: ac(24px, 16px);
      }
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: ac(24px, 20px);

    @media (min-width: 902px) {
      &:not(:last-child) {
        margin-bottom: ac(24px, 16px);
      }
    }

    @media (max-width: 901px) {
      width: 200px;
    }

    @media (max-width: 551px) {
      width: 160px;
      border-radius: 16px;
    }

    @media (max-width: 425px) {
      width: 132px;
      border-radius: 12px;
    }
  }

  &__item-img {
    width: 100%;
    overflow: hidden;
    border-radius: ac(24px, 20px);

    @media (max-width: 551px) {
      border-radius: 16px;
    }
  }
}
