/*h1 {
  font-family: var(--font-second);
  font-size: ac(56px, 32px);
  line-height: 1.2;
}

h2 {
  font-family: var(--font-second);
  font-size: ac(48px, 32px);
  line-height: 1.2;
}

h3 {
  font-family: var(--font-second);
  font-size: ac(44px, 24px);
  line-height: 1.2;
}

h4 {
  font-size: ac(32px, 22px);
  line-height: 112.5%;
  font-weight: 600;
}

h5 {
  font-size: ac(26px, 22px);
  line-height: 1.2;
  font-weight: 600;
}

h6 {
  font-size: ac(24px, 22px);
  line-height: 1.4;
  font-weight: 500;
}*/

a {
  display: inline-block;
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    a {
      font: inherit;
      text-decoration: underline;
      transition: color 0.3s ease;
      word-wrap: break-word;
      overflow-wrap: break-word;

      &:hover {
        color: var(--cl-lilac-500);
      }
    }
  }

  ol {
    list-style-type: none;
    counter-reset: num;
    display: grid;
    li {
      position: relative;
      padding-left: ac(26px, 24px);
      &:before {
        position: absolute;
        color: var(--cl-black);
        top: 0;
        left: 5px;
        font: inherit;
        font-weight: 600;
        content: counter(num) '.';
        counter-increment: num;
      }

      &:not(:last-child) {
        margin-bottom: ac(14px, 11px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }
  }

  ul {
    display: grid;

    li {
      position: relative;
      display: block;
      padding-left: ac(26px, 24px);

      &:before {
        position: absolute;
        background: var(--cl-black);
        border-radius: 50%;
        content: '';
        width: 5px;
        height: 5px;
        top: 8px;
        left: ac(11px, 8px);
      }

      &:not(:last-child) {
        margin-bottom: ac(14px, 11px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }
  }

  ul,
  ol,
  p {
    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &[data-simplebar] {
    width: calc(100% + 10px);
    padding-right: 10px;

    .simplebar-track.simplebar-vertical {
      width: ac(4px, 2px);
      background: var(--cl-gray-line);
      border-radius: 2px;
      transition: opacity 0.3s ease;

      .simplebar-scrollbar {
        background: var(--cl-primary);
        border-radius: 2px;

        &:before {
          content: none;
        }
      }
    }
  }

  &.second-list {
    ul {
      li {
        line-height: 1.4;
        padding-left: ac(36px, 28px);
        letter-spacing: 0.005em;

        strong {
          font-weight: 600;
          font-feature-settings: 'pnum' on, 'lnum' on;
        }

        a {
          font-weight: 600;
          font-feature-settings: 'pnum' on, 'lnum' on;
        }

        &:not(:last-child) {
          margin-bottom: ac(12px, 8px);
        }

        &:before {
          font-family: 'icomoon' !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          position: absolute;
          background: transparent;
          border-radius: 0;
          content: '\e904';
          width: ac(24px, 20px);
          height: ac(24px, 20px);
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          font-size: ac(21px, 20px);
          color: var(--cl-lilac-400);
        }
      }
    }
  }

  &.body-text-1 {
    a {
      font-weight: 500;
    }
  }

  &.body-text-2 {
    a {
      font-weight: 500;
    }
  }
}

.dark-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--cl-white);
  }
}

.headline-1 {
  font-family: var(--font-second);
  font-size: ac(60px, 30px);
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.headline-2 {
  font-family: var(--font-second);
  font-size: ac(44px, 26px);
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.headline-3 {
  font-family: var(--font-second);
  font-size: ac(36px, 24px);
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.headline-4 {
  font-family: var(--font-second);
  font-size: ac(36px, 24px);
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;

  strong,
  b {
    font-weight: 700;
  }

  p,
  ul {
    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }
}

.headline-5 {
  font-family: var(--font-second);
  font-size: ac(32px, 22px);
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.headline-6 {
  font-family: var(--font-main);
  font-size: ac(28px, 20px);
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.subtitle-1 {
  font-family: var(--font-main);
  font-size: ac(28px, 20px);
  line-height: 1.4;
  font-weight: 500;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.subtitle-2 {
  font-family: var(--font-main);
  font-size: ac(24px, 18px);
  line-height: 1.4;
  font-weight: 700;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.subtitle-3 {
  font-family: var(--font-main);
  font-size: ac(24px, 18px);
  line-height: 1.4;
  font-weight: 400;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.subtitle-4 {
  font-family: var(--font-main);
  font-size: ac(20px, 17px);
  line-height: 1.4;
  font-weight: 700;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.button-text-1 {
  font-family: var(--font-main);
  font-size: 16px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.button-text-2 {
  font-family: var(--font-main);
  font-size: ac(18px, 16px);
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.body-text-1 {
  font-family: var(--font-main);
  font-size: ac(20px, 18px);
  line-height: 1.36;
  font-weight: 400;

  p,
  ul {
    &:not(:last-child) {
      margin-bottom: ac(14px, 12px);
    }
  }
}

.body-text-2 {
  font-family: var(--font-main);
  font-size: ac(18px, 16px);
  line-height: 1.4;
  font-weight: 400;

  p,
  ul {
    &:not(:last-child) {
      margin-bottom: ac(12px, 10px);
    }
  }

  /*&[data-simplebar] {
    width: calc(100% + 10px);
    padding-right: 10px;
  }*/
}

.body-text-3 {
  font-family: var(--font-main);
  font-size: ac(18px, 16px);
  line-height: 1.4;
  font-weight: 500;

  p,
  ul {
    &:not(:last-child) {
      margin-bottom: ac(10px, 10px);
    }
  }
}

.text-link {
  font-family: var(--font-main);
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
}

.caption-1 {
  font-family: var(--font-main);
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.caption-2 {
  font-family: var(--font-main);
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
}

.caption-3 {
  font-family: var(--font-main);
  font-size: 14px;
  line-height: 1.36;
  font-weight: 400;
}

.caption-4 {
  font-family: var(--font-main);
  font-size: 14px;
  line-height: 1.36;
  font-weight: 400;
  text-transform: uppercase;
}
