.text-col-section {
  padding-top: ac(80px, 30px);
  padding-bottom: ac(80px, 30px);
  overflow: hidden;
  position: relative;

  &__wrap {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 640px) {
      flex-direction: column;
      justify-content: flex-start;
      grid-row-gap: 20px;
    }
  }

  &__first-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 53.59%;

    .headline-4 {
      line-height: 120%;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &__second-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 43.95%;

    @media (max-width: 640px) {
      width: 100%;
    }

    /*.body-text-1 {
      font-weight: 500;
    }*/

    .text-col-section__text {
      width: calc(100% + 5px);
    }
  }

  &__page-decor-container {
    top: 0;
    left: 0;
    right: 0;
    height: 579px;
    position: absolute;
    overflow: hidden;

    &:after {
      position: absolute;
      left: 0;
      bottom: -30px;
      right: 0;
      content: '';
      top: ac(350px, 20px);
      z-index: 2;

      background: linear-gradient(0, #ffffff 5%, rgba(115, 115, 115, 0) 100%);
    }
  }

  &__page-decor {
    top: ac(48px, 20px);
    --arw: 1956.48;
    --arh: 1567.23;
    width: ac(1956px, 1000px);
    position: absolute;
    left: 60%;
    transform: translateX(-64.5%);
    z-index: 1;
  }

  &.home-page {
    overflow: visible;
  }

  &.cooperation-page {
    overflow: visible;
    padding-bottom: 24px;

    .text-col-section__page-decor-container {
      &:after {
        @media (min-width: 1200px) {
          top: ac(440px, 200px);
        }
      }
    }
  }

  + .text-image-section {
    padding-top: 0;
    padding-bottom: ac(65px, 30px);
    position: relative;
    z-index: 20;
    margin-top: ac(-5px, 0px);
  }
}
