.buttons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.btn {
  border: 1px solid var(--cl-white);
  border-radius: 100px;
  padding-left: ac(27px, 22px);
  padding-right: ac(27px, 22px);
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: ac(56px, 48px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ac(8px, 6px);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08),
    0px 1px 3px 1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.3s;

  &__text {
    font-size: ac(18px, 16px);
    leading-trim: both;
    text-edge: cap;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--cl-primary);
    transition: 0.3s;
    line-height: 1;
    font-weight: 700;

    @media (max-width: 360px) {
      font-size: 15px;
      letter-spacing: 0.02em;
    }

    @media (max-width: 340px) {
      font-size: 14px;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(22px, 20px);
    color: var(--cl-primary);
    transition: 0.3s;

    i.icon-instagram {
      margin-top: -1px;
    }
  }

  &:hover {
    border-color: var(--cl-lilac-950);
    background-color: var(--cl-lilac-450);

    .btn__text {
      color: var(--cl-lilac-950);
    }

    .btn__icon {
      color: var(--cl-lilac-950);
    }
  }

  &.btn-second {
    border-color: var(--cl-lilac-500);
    background-color: var(--cl-lilac-500);

    .btn__text {
      color: var(--cl-white);
    }

    .btn__icon {
      color: var(--cl-white);
    }

    &:hover {
      border-color: var(--cl-lilac-850);
      background-color: var(--cl-lilac-400);

      .btn__text {
        color: var(--cl-lilac-850);
      }

      .btn__icon {
        color: var(--cl-lilac-850);
      }
    }
  }

  &.btn-second-light {
    border-color: var(--cl-lilac-450);
    background-color: var(--cl-lilac-50);

    .btn__text {
      color: var(--cl-lilac-850);
    }

    .btn__icon {
      color: var(--cl-lilac-500);
    }

    &:hover {
      border-color: var(--cl-lilac-850);
      background-color: var(--cl-lilac-500);

      .btn__text {
        color: var(--cl-white);
      }

      .btn__icon {
        color: var(--cl-white);
      }
    }
  }

  &.btn-light {
    border-color: var(--cl-lilac-50);
    background-color: var(--cl-lilac-50);

    .btn__text {
      color: var(--cl-lilac-850);
    }

    .btn__icon {
      color: var(--cl-lilac-500);
    }

    &:hover {
      border-color: var(--cl-lilac-850);
      background-color: var(--cl-lilac-850);

      .btn__text {
        color: var(--cl-white);
      }

      .btn__icon {
        color: var(--cl-white);
      }
    }
  }
}

.category-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--cl-lilac-450);
  background: var(--cl-white);
  transition: 0.3s;
  cursor: pointer;
  padding: ac(13px, 11px) ac(27px, 21px);
  font-size: ac(16px, 14px);
  font-weight: 700;
  line-height: 1.2;
  color: var(--cl-lilac-850);

  &:hover {
    border-color: var(--cl-lilac-500);
    background-color: var(--cl-lilac-500);
    color: var(--cl-white);
  }
}

/*.slider-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 12px;
  position: relative;
  z-index: 10;
}

.slider-btn {
  --size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  transition: background-color 0.3s ease, opacity 0.3s ease;
  cursor: pointer;
  border: 1px solid var(--cl-orange);
  border-radius: 8px;
  background: transparent;
  margin-top: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);

  i {
    font-size: 20px;
    color: var(--cl-orange);
    transition: color 0.3s ease;
  }

  &.swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    &:not(.swiper-button-disabled) {
      background-color: var(--cl-orange);
      i {
        color: var(--cl-white);
      }
    }
  }

  &.swiper-button-lock {
    display: none;
  }
}*/

.swiper-buttons {
  width: fit-content;
  display: flex;
  gap: 12px;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  margin: 0 auto;

  .swiper-button {
    position: relative;
    transform: none;
    margin: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    display: flex;
    --size: ac(56px, 48px);
    width: var(--size);
    height: var(--size);
    justify-content: center;
    align-items: center;
    color: var(--cl-lilac-500, #346c50);
    border: 1px solid var(--cl-lilac-450);
    border-radius: 50%;
    transition: 0.3s;
    background-color: transparent;

    &::after {
      display: none;
    }
    &::before {
      content: '\e90c';
      display: block;
      font-family: 'icomoon' !important;
      font-size: ac(24px, 22px);
    }
    &.swiper-button-prev {
      &::before {
        content: '\e90b';
      }
    }
    &:hover {
      background-color: var(--cl-lilac-500);
      color: var(--cl-white);
    }
    &.swiper-button-lock {
      display: none;
    }

    &.swiper-button-second {
      &::before {
        content: '\e91c';
        display: block;
        font-family: 'icomoon' !important;
        font-size: ac(32px, 28px);
      }
      &.swiper-button-prev {
        &::before {
          content: '\e91b';
        }
      }
    }

    &.swiper-button-dark {
      border-color: var(--cl-lilac-500);
      background-color: var(--cl-lilac-500);
      color: var(--cl-white);

      &:hover {
        background-color: var(--cl-lilac-850);
      }
    }
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.social-btn {
  --size: ac(48px, 40px);
  border: 1px solid var(--cl-lilac-500);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: var(--cl-lilac-500);
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease;

  .icon-svg,
  i {
    position: relative;
    z-index: 5;
  }

  i {
    font-size: ac(20px, 18px);
    color: var(--cl-lilac-50);
    transition: color 0.3s ease;
  }

  &:hover {
    background-color: var(--cl-lilac-50);

    i {
      color: var(--cl-lilac-850);
    }
  }
}

.close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--cl-gray-line);
  transition: all 0.3s ease;
  width: ac(40px, 32px);
  height: ac(40px, 32px);
  position: relative;
  z-index: 10;
  cursor: pointer;

  i {
    color: var(--cl-mid-gray);
    font-size: ac(12px, 11px);
    transition: color 0.3s ease;
  }

  &:hover {
    border-color: var(--cl-sky);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

    i {
      color: var(--cl-orange);
    }
  }
}

.btn-icon {
  --size: ac(56px, 48px);
  --icon-size: ac(24px, 20px);
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--cl-lilac-500);
  border-radius: 50%;
  background: var(--cl-lilac-500);
  transition: 0.3s;
  position: relative;

  &__count {
    position: absolute;
    right: 0;
    z-index: 5;
    top: ac(-8px, -6px);
    border-radius: 50%;
    background: var(--cl-red);
    display: flex;
    justify-content: center;
    align-items: center;
    --size: ac(24px, 20px);
    width: var(--size);
    height: var(--size);
    padding: 4px;

    span {
      font-size: ac(12px, 11px);
      font-weight: 600;
      color: var(--cl-white);
    }
  }

  .icon-svg {
    width: var(--icon-size);
    height: var(--icon-size);

    svg {
      path {
        transition: all 0.3s ease;
        fill: var(--cl-black);
      }
    }
  }

  i {
    font-size: var(--icon-size);
    color: var(--cl-lilac-50);
    transition: 0.3s;
  }

  &:hover {
    background-color: var(--cl-lilac-450);
    .icon-svg {
      svg {
        path {
          fill: var(--cl-orange);
        }
      }
    }

    i {
      color: var(--cl-lilac-850);
    }
  }

  &.btn-icon-light {
    border-color: var(--cl-lilac-450);
    background-color: var(--cl-lilac-50);

    i {
      color: var(--cl-lilac-500);
    }

    &:hover {
      background-color: var(--cl-lilac-450);

      i {
        color: var(--cl-lilac-850);
      }
    }
  }
}
