.testimonials-section {
  --slider-margin: ac(48px, 32px);
  padding-top: ac(80px, 30px);
  padding-bottom: ac(80px, 30px);
  overflow: hidden;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: var(--slider-margin);
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 24px;
    }
  }

  &__content {
    width: 100%;

    @media (min-width: 769px) {
      max-width: ac(600px, 390px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(15px, 24px);
    }
  }

  &__text {
    color: var(--cl-lilac-500);
    font-weight: 500;
  }

  &__buttons {
    margin-right: ac(148px, 120px);
    padding-bottom: ac(4px, 0px);

    .btn.btn-second {
      min-height: 48px;
      gap: 6px;

      .btn__text {
        font-size: 16px;
      }

      .btn__icon {
        font-size: 20px;
        margin-bottom: 2px;
      }
    }

    @media (max-width: 951px) {
      margin-right: 0;
    }
  }

  &__slider {
    overflow: visible;

    @media (min-width: 952px) {
      .swiper-buttons {
        position: absolute;
        z-index: 5;
        right: 0;
        top: calc(var(--slider-margin) * -1);
        transform: translateY(-100%);

        .slider-btn {
          margin-top: 0;
        }
      }
    }

    @media (max-width: 951px) {
      .swiper-buttons {
        width: 100%;
        justify-content: center;

        .swiper-button {
          margin-top: ac(40px, 32px);
        }
      }
    }
  }

  &__image-item {
    border: 1px solid var(--cl-lilac-450);
    border-radius: 12px;
    width: ac(288px, 279px);
    overflow: hidden;

    &:hover {
      .testimonials-section__image-icon {
        opacity: 1;
      }

      .testimonials-section__image {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__image {
    width: 100%;
    --arw: 288;
    --arh: 513;
    border-radius: 12px;
    overflow: hidden;

    &:after {
      position: absolute;
      inset: 0;
      background: rgba(41, 40, 62, 0.4);
      content: '';
      z-index: 1;
      opacity: 0;
      transition: 0.3s;
    }
  }

  &__image-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    --size: ac(88px, 64px);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 1px solid var(--cl-lilac-450);
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08),
      0px 1px 3px 1px rgba(0, 0, 0, 0.12);
    transition: 0.3s;
    opacity: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: ac(32px, 24px);
      color: var(--cl-white);
    }
  }

  &__item {
    border: 1px solid var(--cl-gray-line);
    border-radius: 12px;
    background: var(--cl-light-bg);
    padding: 23px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    width: ac(376px, 350px);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);

    @mixin media 451 {
      width: 100%;
    }
  }

  &__item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: var(--cl-orange);

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__item-text {
    max-height: 310px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__item-bottom {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 12px;
  }

  &__item-image {
    --size: 64px;
    aspect-ratio: 1 / 1;
    width: var(--size);
    min-width: var(--size);
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__item-info {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    grid-row-gap: 4px;
  }

  &__item-subtitle {
    color: var(--cl-sky);
  }

  @mixin xxxl {
    .testimonials-section {
      &__image-item {
        width: 320px;
      }

      &__item-text {
        max-height: 390px;
      }
    }
  }
}
