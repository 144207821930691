.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-primary) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-white);

  color: var(--cl-primary);
  font-family: var(--font-main);
  font-size: ac(20px, 16px);
  line-height: 1.36;

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  flex-direction: column;
  align-items: stretch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-gray-line);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-primary);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @mixin media 1025 {
    overflow: hidden;
  }
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1224px;
  width: perc(1224);

  @media (max-width: 1350px) {
    width: 90%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

section,
footer,
header {
  position: relative;
  width: 100%;
  max-width: 100vw;
}

.sections-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  /*@media (min-width: 902px) {
    max-width: calc(100% - var(--cart-width));

    section {
      width: 100%;

      .cont {
        width: 91%;

        @mixin media 1500 {
          @mixin min-media 1400 {
            margin-right: 48px;
          }
        }
      }
    }
  }*/
}

.swiper {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.swiper-mobile-helper {
  position: absolute;
  width: 50px;
  height: 50px;
  opacity: 0;
  pointer-events: none;
  will-change: transform, opacity;
  animation: none;
  z-index: 20;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.show {
    animation: swiperHelper 1.3s ease 2 both;
  }
}

@keyframes swiperHelper {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  39% {
    opacity: 1;
  }
  56% {
    opacity: 0.5;
  }
  77% {
    transform: translateX(-150%);
    opacity: 0;
  }
}

.connection {
  --main-bg: var(--cl-white);
  --second-bg: var(--cl-sky);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-49.5%);
  aspect-ratio: 2050 / 52;
  background: var(--second-bg);
  z-index: 1;
  width: 142.36vw;
  min-width: 1014px;

  &:before {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    bottom: -1px;
    background: var(--main-bg);
    content: '';
    z-index: 2;
    clip-path: url('#connection-clip-path');
  }

  @mixin max-sm {
    transform: translateX(-46.5%);
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: var(--title-width, 100%);

  .headline-1 {
    &:not(:last-child) {
      margin-bottom: ac(32px, 18px);
    }
  }

  .headline-2,
  .headline-3 {
    &:not(:last-child) {
      margin-bottom: ac(24px, 18px);
    }
  }

  @mixin max-sm {
    --title-width: 100% !important;
  }
}

.title-decor {
  overflow: hidden;
  max-width: calc(100% + ac(32px, 20px));
  height: 16px;

  svg {
    width: 100%;
    max-height: 100%;
    min-width: 1490px;

    path {
      stroke: var(--title-decor-color);
      stroke-width: 4px;
    }

    @mixin media 390 {
      min-width: 1060px;
    }
  }

  &[data-view] {
    opacity: 0;
    transition: opacity 1s ease;

    &.animated {
      opacity: 1;
      svg {
        path {
          fill: transparent;
          animation: dash 2.5s linear forwards;
          /*animation-direction: reverse;*/
        }
      }
    }
  }

  @mixin max-sm {
    width: 100%;
    max-width: 100%;
  }
}

@keyframes dash {
  100% {
    stroke-dashoffset: 0;
  }
}
.image-aspect-ratio {
  position: relative;
  overflow: hidden;
  mask-image: radial-gradient(white, black);

  &::before {
    content: '';
    display: block;
    padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
  }

  img,
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
  }

  &[data-parallax-el] {
    overflow: visible;
    mask-image: none;

    img,
    svg {
      position: absolute !important;
    }
  }
}

.max-line-length-number {
  padding: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-line-num);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.grecaptcha-badge{
  z-index: 50;
}
