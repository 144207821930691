.text-dog-section {
  padding-top: ac(64px, 30px);
  padding-bottom: ac(56px, 30px);
  position: relative;
  overflow: hidden;

  &__decor {
    --arw: 638.1;
    --arh: 741.7;
    position: absolute;
    width: ac(502px, 300px);

    &:first-child {
      top: 58%;
      left: ac(-320px, -190px);
      transform: translateY(-50%) translateX(-100px);
      opacity: 0;
      transition: 0.6s;

      &.animated {
        transform: translateY(-50%) translateX(0);
        opacity: 1;
      }
    }

    &:last-child {
      top: 27.61%;
      right: ac(-312px, -150px);
      max-width: 466px;
    }

    @media (max-width: 551px) {
      opacity: 0.5;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 10;
  }

  &__text {
    width: 100%;
    max-width: ac(872px, 600px);
    text-align: center;
    font-family: var(--font-second);
    font-size: ac(32px, 22px);
    line-height: 1.4;
    letter-spacing: 0.01em;

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__logo {
    --arw: 152;
    --arh: 131.42;
    width: 152px;

    img {
      object-fit: contain;
    }
  }
}
