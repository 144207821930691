.clients {
  padding-top: ac(64px, 30px);
  padding-bottom: ac(80px, 40px);
  overflow: hidden;
  position: relative;
  z-index: 20;

  &__title {
    margin-bottom: ac(48px, 27px);
    width: 100%;
    text-align: center;

    h2 {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      display: inline-block;
      --size: ac(48px, 24px);
      width: var(--size);
      height: var(--size);
      margin-left: ac(10px, 5px);
    }

    @media (max-width: 374px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 250px;

      svg {
        min-width: 36px;
        --size: 36px;
        display: none;
      }
    }
  }

  &__marquee {
    &:not(:last-child) {
      margin-bottom: ac(12px, 10px);
    }
  }

  .js-marquee {
    gap: ac(12px, 10px);
    display: flex;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding: ac(16px, 10px) ac(26px, 16px);*/
    background: var(--cl-white);
    width: 244px;
    height: 140px;
    border: 1px solid var(--cl-lilac-450);
    border-radius: ac(12px, 8px);
    overflow: hidden;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    &:nth-child(2n) {
      .clients {
        &__logo-decor {
          bottom: auto;
          top: -5px;
          transform: scaleY(-1) rotate(-32deg);
        }
      }
    }
  }

  &__logo-decor {
    --arw: 51.55;
    --arh: 48.06;
    width: ac(51px, 46px);
    position: absolute;
    bottom: -5px;
    right: -5px;
    z-index: 5;
    pointer-events: none;
    transform: rotate(-32deg);
  }

  @media (min-width: 1536px) {
    .cont {
      max-width: 100%;
      padding: 0;
    }
  }

  @media (min-width: 2100px) {
    .cont {
      max-width: 100%;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .clients {
      &__logo {
        padding: 20px;
        width: 200px;
        height: 116px;
      }
    }
  }

  @media (max-width: 551px) {
    .clients {
      &__logo {
        height: 94px;
        padding: 4px 8px;
        width: 160px;
      }
    }
  }
}
