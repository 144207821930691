.cart-container-page-container {
  padding: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 800;
  pointer-events: none;
  overflow: hidden;

  @media (min-width: 902px) {
    z-index: 1100 !important;
  }
}

.cart-container {
  --open-delay: 0.5s;
  --cart-width: 160px;
  --cart-open-width: 500px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: var(--cart-width);
  z-index: 800;
  pointer-events: none;

  @media (min-width: 902px) {
    z-index: 1100 !important;
  }

  .cart-close-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: var(--top-nav, 0);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.0);
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    display: block;
  }

  @media (min-width: 903px) {

    &.is-open {
      .cart-close-wrapper {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
      }
    }
  }

  /*.cart-open {
    position: absolute;
    width: 36px;
    height: 86px;
    top: 50%;
    left: 1px;
    z-index: 20;
    transform: translateY(-50%) translateX(-100%);
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.08));
    padding-left: 2px;
    transition: filter 0.3s ease, left var(--open-delay);

    i {
      font-size: 15px;
      color: var(--cl-darked-gray);
      transition: color 0.3s ease, transform var(--open-delay) ease;
    }

    &:hover {
      filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.12))
        drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.08));

      i {
        color: var(--cl-orange);
      }
    }

    &:before {
      position: absolute;
      inset: 0;
      content: '';
      z-index: -1;
      background-image: url('../../assets/static/cart-open-btn.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }*/

  .cart {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transition: width var(--open-delay) ease, opacity var(--open-delay) ease,
      transform var(--open-delay) ease;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: var(--cl-white);
    overflow: hidden;
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: #c5c3e3;
    box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 0px ac(56px, 32px);
    pointer-events: none;
    opacity: 0;
    transform: scaleX(100%);
    transform-origin: right;

    &__close {
      position: absolute;
      top: ac(41px, 30px);
      right: ac(40px, 20px);
      z-index: 100;
      width: ac(40px, 36px);
      height: ac(40px, 36px);
      border-color: var(--cl-lilac-450);
      opacity: 0;
      transition: var(--open-delay);

      i {
        font-size: 15px;
        color: var(--cl-red);
      }

      &:hover {
        transition: 0.3s;
        background-color: var(--cl-lilac-400);
        border-color: var(--cl-red);
      }
    }

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: ac(118px, 80px);
      text-align: left;
      text-transform: uppercase;
      font-family: var(--font-second);
      font-weight: 700;
      line-height: 1.4;
      font-size: 18px;
      transition: all var(--open-delay) ease;
      background: var(--cl-white);
      padding-left: ac(40px, 20px);
      padding-right: ac(40px, 20px);
      letter-spacing: 0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      white-space: nowrap;
    }

    &__list {
      margin-top: ac(-20px, 0px);
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      height: calc(100% - 100px);
      position: relative;
      padding-top: 8px;

      &[data-simplebar] {
        .simplebar-track.simplebar-vertical {
          width: ac(8px, 6px);
          background: var(--cl-gray-line);
          border-radius: ac(12px, 8px);
          bottom: 40px;
          top: 40px;
          right: ac(6px, 4px);

          .simplebar-scrollbar {
            opacity: 0.8;
            background: var(--cl-primary);
            border-radius: ac(8px, 5px);
            width: ac(4px, 3px);
            left: ac(2px, 1.5px);
            clip-path: inset(
              ac(2px, 1.5px) 0 ac(2px, 1.5px) 0 round ac(8px, 5px)
            );

            &:before {
              content: none;
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      &:before,
      &:after {
        position: absolute;
        left: 0;
        right: 0;
        content: '';
        height: 16px;
        transition: height var(--open-delay) ease;
        z-index: 5;
      }

      &:before {
        top: 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      &:after {
        bottom: 0;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }

    &__list-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }

    &__item {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      position: relative;
      padding: 20px ac(40px, 20px) 20px ac(40px, 20px);
      transition: padding-bottom var(--open-delay) ease,
        padding-left var(--open-delay) ease;
      overflow: hidden;
      color: var(--cl-black);

      &:after {
        position: absolute;
        bottom: 0;
        left: ac(40px, 20px);
        right: ac(40px, 20px);
        height: 1px;
        border-bottom: 1px solid #e6e6e6;
        content: '';
        transition: opacity 0.3s ease;
        z-index: 1;
        opacity: 0;
      }

      &:not(:last-child) {
        &:after {
          opacity: 1;
        }
      }
    }

    &__item-link {
      position: absolute;
      inset: 0;
      z-index: 1;
    }

    &__item-image {
      @mixin aspect-ratio 1, 1;
      border: 1px solid var(--cl-lilac-450);
      border-radius: 8px;
      width: 108px;
      transition: width var(--open-delay) ease;
      position: relative;
      z-index: 10;
    }

    &__item-container {
      width: 0;
      transition: width var(--open-delay) ease, padding var(--open-delay) ease;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__item-content {
      width: 264px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      min-height: 108px;
      position: absolute;
      bottom: 20px;
      opacity: 0;
      transition: opacity var(--open-delay) ease;
    }

    &__item-title {
      transition: color 0.3s ease;
      @mixin max-line-length 3;
      font-size: ac(20px, 18px);
      font-family: var(--font-main);
      font-weight: 700;
      line-height: 1.4;
      color: var(--cl-lilac-850);
      font-feature-settings: 'pnum' on, 'lnum' on;
      max-width: 174px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__item-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--cl-lilac-850);
      font-feature-settings: 'pnum' on, 'lnum' on;
      max-width: 174px;
      margin-bottom: 4px;

      &.with-sale {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    &__info-price {
      font-size: ac(24px, 22px);
      font-weight: 500;
      font-family: var(--font-main);
      line-height: 1.4;
      color: var(--cl-lilac-850);
      font-feature-settings: 'pnum' on, 'lnum' on;
    }

    &__info-unit {
      font-size: ac(24px, 22px);
      font-weight: 500;
      font-family: var(--font-main);
      line-height: 1.4;
      color: var(--cl-lilac-500);
      font-feature-settings: 'pnum' on, 'lnum' on;
    }

    &__info-old {
      font-size: 13px;
      font-weight: 500;
      font-family: var(--font-main);
      line-height: 18px;
      color: var(--cl-lilac-500);
      font-feature-settings: 'pnum' on, 'lnum' on;
      text-decoration: line-through;
    }

    &__info-sale {
      font-size: ac(24px, 22px);
      font-weight: 700;
      font-family: var(--font-main);
      line-height: 1.4;
      color: var(--cl-red);
      font-feature-settings: 'pnum' on, 'lnum' on;
    }

    &__info-text {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
      color: var(--cl-lilac-500);
      margin-left: ac(5px, 4px);
      text-transform: uppercase;
      white-space: nowrap;
      font-feature-settings: 'pnum' on, 'lnum' on;

      span {
        font-size: ac(22px, 22px);
        font-weight: 800;
        line-height: normal;
        font-family: var(--font-second);
        color: var(--cl-lilac-450);
        font-feature-settings: 'pnum' on, 'lnum' on;
      }
    }

    &__item-counter {
      position: absolute;
      top: 50%;
      right: ac(40px, 20px);
      transform: translateY(-50%) translateX(50px);
      z-index: 5;
      opacity: 0;
      transition: var(--open-delay);
    }

    &__item-close {
      position: absolute;
      top: 50%;
      left: ac(44px, 20px);
      transform: translateY(-50%) translateX(50px);
      opacity: 0;
      font-size: 15px;
      color: var(--cl-red);
      z-index: 10;

      &:not(:hover) {
        transition: all var(--open-delay) ease;
      }

      &:hover {
        color: var(--cl-lilac-500);
      }
    }

    &__visible-bottom {
      position: absolute;
      left: 0;
      bottom: 0;
      width: var(--cart-width);
      z-index: 19;
      transition: opacity var(--open-delay) ease;
      opacity: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;

      &:after {
        border-top: 1px solid var(--cl-gray-line);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: var(--cart-open-width);
        background: var(--cl-white);
        content: '';
        z-index: -1;
      }
    }

    &__list-more {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px 10px;
      grid-row-gap: 16px;
      cursor: pointer;

      &:hover {
        .cart {
          &__list-more-icons {
            span {
              &:nth-child(1) {
                background-color: var(--cl-green);
              }

              &:nth-child(2) {
                background-color: var(--cl-orange);
              }

              &:nth-child(3) {
                background-color: var(--cl-sky);
              }
            }
          }

          &__list-more-text {
            color: var(--cl-orange);
          }
        }
      }
    }

    &__list-more-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      span {
        display: block;
        width: 4px;
        height: 4px;
        background: var(--cl-orange);
        border-radius: 50%;
        transition: background-color 0.3s ease;

        &:nth-child(2) {
          background-color: var(--cl-sky);
        }

        &:nth-child(3) {
          background-color: var(--cl-green);
        }
      }
    }

    &__list-more-text {
      font-size: 16px;
      font-weight: 500;
      font-family: var(--font-main);
      line-height: normal;
      transition: color 0.3s ease;
      text-align: center;
    }

    &__first-result {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 24px 9px 20px;
      position: relative;
      z-index: 2;

      &:after {
        border-top: 1px solid var(--cl-gray-line);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: var(--cart-open-width);
        background: var(--cl-light-bg);
        content: '';
        z-index: -1;
      }

      .cart__result-sum {
        font-size: 20px;
      }
    }

    &__first-result-text {
      color: var(--cl-mid-gray);
      font-size: 16px;
      font-weight: 500;
      font-family: var(--font-main);
      text-align: center;
      margin-bottom: 1px;
    }

    &__first-result-btn {
      width: 100%;
      margin-top: 18px;
    }

    &__result {
      position: absolute;
      left: 0;
      bottom: 0;
      width: var(--cart-width);
      z-index: 20;
      transition: opacity var(--open-delay) ease;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      pointer-events: none;
    }

    &__result-container {
      width: var(--cart-open-width);
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      grid-column-gap: 12px;
      grid-row-gap: 14px;
      padding: 24px ac(40px, 20px) ac(40px, 32px);
      background: var(--cl-white);
    }

    /*&__delivery-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 4px;
      margin-right: auto;
    }

    &__delivery-info-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      color: var(--cl-dark-gray);
      max-width: 198px;
    }

    &__delivery-sum {
      font-size: 20px;
      font-family: var(--font-main);
      font-weight: 600;
      line-height: normal;
    }*/

    &__result-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      grid-row-gap: ac(24px, 20px);
      grid-column-gap: 20px;
      width: 100%;
    }

    &__result-title {
      font-size: ac(18px, 17px);
      font-weight: 700;
      letter-spacing: 0.04em;
      line-height: 1.2;
      color: var(--cl-lilac-500);
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }

    &__result-sum {
      font-size: ac(28px, 24px);
      font-family: var(--font-second);
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: 0.01em;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-column-gap: ac(6px, 4px);
      font-feature-settings: 'pnum' on, 'lnum' on;

      span {
        font-feature-settings: 'pnum' on, 'lnum' on;
      }
    }

    &__result-buttons {
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-direction: column;
      border-top: 1px solid #dfdfe3;
      padding-top: ac(32px, 20px);
      grid-row-gap: ac(12px, 10px);
    }

    &__result-btn {
      width: 100%;

      &.btn {
        min-height: 48px;
        height: 48px;

        &.btn-light {
          border-color: var(--cl-lilac-450);

          &:hover {
            border-color: var(--cl-lilac-850);
          }
        }
      }

      .btn__text {
        white-space: nowrap;
        font-size: 16px;
      }
    }

    &__result-additional {
      border-top: 1px solid var(--cl-gray-line);
      width: var(--cart-open-width);
      background: var(--cl-light-beige);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 16px 32px;
    }

    &__result-additional-text {
      width: 100%;
    }
  }

  &.is-open {
    /*.cart-open {
      left: calc((var(--cart-open-width) - var(--cart-width)) * -1);

      i {
        transform: scaleX(-1);
      }
    }*/

    .cart {
      width: var(--cart-open-width);
      opacity: 1;
      transform: translateX(0);
      pointer-events: all;

      &__close {
        opacity: 1;
      }

      &__list {
        &:before,
        &:after {
          height: 20px;
        }
      }

      &__item {
        padding-left: ac(72px, 50px);

        @media (max-width: 490px) {
          padding-left: 20px;
        }
      }

      &__item-container {
        padding-left: 16px;
      }

      &__item-content {
        opacity: 1;
      }

      &__item-counter {
        transform: translateY(-50%) translateX(0);
        opacity: 1;
      }

      &__item-close {
        transform: translateY(-50%) translateX(0);
        opacity: 1;
      }

      &__title {
        font-size: ac(36px, 28px);
      }

      &__result {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  /*&:not(.is-open) {
    .cart {
      &__item {
        &:hover {
          .cart {
            &__item-image {
              &:after {
                opacity: 0.2;
              }
            }

            &__item-counter {
              transition: opacity 0.3s ease;
              opacity: 1;
            }
          }
        }
      }
    }
  }*/

  @media (max-width: 901px) {
    /*top: var(--top-nav, 100px);*/
    top: 0;
    width: auto;
    left: 0;
    transition: opacity 0.3s ease;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;

    .cart-close-wrapper {
      display: block;
      background: rgba(0, 0, 0, 0.3);
    }

    .cart-open {
      display: none;
    }

    .cart {
      width: 100%;
      max-width: var(--cart-open-width);
      transform: translateX(100%);
      transition: transform 0.3s ease;

      &__item-image {
        width: 130px;
      }

      &__item-container {
        padding-left: 20px;
      }

      &__item-content {
        opacity: 1;
      }

      /*&__item-counter {
        transition: opacity var(--open-delay) ease;
        left: 178px;
        bottom: 34px;
        transform: translateX(0) translateY(0);
        opacity: 1;
      }

      &__item-close {
        transform: translateY(-50%) translateX(0);
        opacity: 1;
      }*/

      &__title {
        font-size: ac(26px, 22px);
        font-weight: 600;
      }

      &__result {
        opacity: 1;
        pointer-events: auto;
      }

      @media (max-width: 560px) {
        .cart {
          &__title {
            min-height: 64px;
          }

          &__close {
            top: 15px;
          }

          /*&__item-link {
            display: none;
          }*/

          &__item {
            grid-column-gap: 16px;
          }

          &__item-image {
            min-width: 120px;
          }

          &__item-container {
            position: relative;
            width: auto;
            flex: 1;
            padding-left: 0;
          }

          &__item-content {
            position: relative;
            bottom: auto;
            flex: 1;
            width: auto;
          }

          &__item-close {
            position: absolute;
            top: 14px;
            right: 30px;
            margin-top: 0;
            margin-bottom: 0;
            left: auto;
            transform: none;
          }

          &__result {
            width: 100%;
          }

          &__result-container,
          &__result-additional,
          &__item {
            padding-left: 23px;
            padding-right: 24px;
            width: 100%;
          }

          &__result-container,
          &__item {
            padding-top: 16px;
            padding-bottom: 16px;
          }

          &__item-counter {
            transform: none;
          }

          &__list {
            &:before,
            &:after {
              height: 16px;
            }
          }

          &__result-additional {
            padding-top: 8px;
          }
        }
      }

      @media (max-width: 490px) {
        border-radius: 0;
        .cart {
          &__title {
            min-height: 56px;
            font-size: 18px;
          }

          &__item {
            /*display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;*/
            grid-column-gap: 10px;
          }

          &__item-link {
            display: none;
          }

          &__item-title {
            font-size: 16px;
            max-width: calc(100% - 35px);
          }

          &__item-image {
            width: 88px;
            min-width: 88px;
          }

          &__item-container {
            /*position: relative;
            width: auto;
            flex: 1;
            padding-left: 0;*/
          }

          &__item-content {
            padding-top: 0;
            padding-bottom: 0;
            min-height: 90px;
          }

          &__item-counter {
            bottom: 4px;
            top: auto;
            transform: none;
          }

          &__item-price {
            font-size: 14px;
            min-width: 44px;
          }

          &__info-old {
            font-size: 12px;
            line-height: 1.1;
          }

          &__info-sale {
            font-size: 14px;
            min-width: 44px;
          }

          &__item-unit {
            font-size: 14px;
          }

          &__item-close {
            position: absolute;
            top: 14px;
            right: 40px;
            margin-top: 0;
            margin-bottom: 0;
            --size: 20px;
            width: var(--size);
            height: var(--size);

            i {
              font-size: 20px;
            }
          }

          &__result-container,
          &__result-additional,
          &__item {
            padding-left: 19px;
            padding-right: 20px;
          }

          &__result-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            grid-row-gap: 8px;
          }

          &__result-sum {
            font-size: 18px;
            span.hidden {
              display: inline-block;
            }
          }

          &__delivery-info {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            grid-column-gap: 10px;
          }

          &__delivery-info-text {
            max-width: 100%;
            font-size: 14px;
          }

          &__result-content {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            grid-column-gap: 10px;
          }

          &__result-btn {
            width: 100%;
          }

          &__result-additional {
            padding-top: 8px;
            padding-bottom: 8px;
          }
        }
      }

      @media (max-width: 374px) {
        .cart {
          &__result-container,
          &__result-additional,
          &__item {
            padding-left: 11px;
            padding-right: 12px;
          }

          &__item-close {
            right: 20px;
          }

          &__item-counter {
            right: 5px;
          }

          &__info-price {
            font-size: 18px;
          }

          &__info-sale {
            font-size: 18px;
          }

          &__info-unit {
            font-size: 18px;
          }

          &__info-unit {
            font-size: 18px;
          }
        }
      }
    }

    &.is-open {
      opacity: 1;
      pointer-events: auto;

      .cart-close-wrapper {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        cursor: pointer;
      }

      .cart {
        width: 100%;
        max-width: var(--cart-open-width);
        transform: translateX(0);
      }
    }
  }
}
