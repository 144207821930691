.catalogue-section {
  padding-top: ac(108px, 90px);
  padding-bottom: ac(120px, 30px);
  background: var(--cl-white);
  overflow: hidden;

  &__wrap {
    padding-top: ac(52px, 48px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(35px, 24px);
    }
  }

  &__categories {
    position: relative;
    overflow: visible;
    width: 100%;
    padding-bottom: ac(39px, 22px);
    border-bottom: 1px solid var(--cl-grey-2);

    &:not(:last-child) {
      margin-bottom: ac(35px, 22px);
    }

    .swiper {
      overflow: visible;

      &:not(.swiper-initialized) {
        .swiper-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          flex-wrap: wrap;
        }
      }
    }
  }

  &__category {
    font-family: var(--font-main);
    font-size: 16px;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    padding: ac(14px, 12px) ac(27px, 20px);
    border: 1px solid var(--cl-lilac-450);
    background: var(--cl-lilac-50);
    border-radius: 100px;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    width: min-content;
    height: auto;

    &:hover {
      &:not(.active) {
        border-color: var(--cl-lilac-500);
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08),
          0px 1px 3px 1px rgba(0, 0, 0, 0.12);
      }
    }

    &.active {
      border-color: var(--cl-lilac-500);
      background-color: var(--cl-lilac-500);
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08),
        0px 1px 3px 1px rgba(0, 0, 0, 0.12);
      color: var(--cl-white);

      &:hover {
        background-color: var(--cl-lilac-400);
      }
    }
  }

  &__helper {
    top: -30px;
    right: -10px;

    @media (min-width: 769px) {
      display: none;
    }
  }

  &__search {
    position: relative;
    width: 100%;
    max-width: 600px;

    input {
      background-color: var(--cl-white);
      border-radius: 100px;
      border-color: var(--cl-lilac-450);
      height: ac(56px, 48px);
      padding-left: ac(52px, 46px);
      font-size: 16px;
      box-shadow: none;
      letter-spacing: 0.04em;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__search-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: var(--cl-lilac-850);
    left: ac(20px, 16px);
    top: 53%;
    transform: translateY(-50%);
  }

  &__search-title {
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;

    strong {
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__result {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__result-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: ac(24px, 16px);
    grid-row-gap: ac(21px, 20px);

    &:not(:last-child) {
      margin-bottom: ac(48px, 30px);
    }

    @media (max-width: 1279px) {
      @media (min-width: 1024px) {
        grid-column-gap: ac(16px, 12px);

        .product-card__content {
          padding-left: ac(12px, 8px);
          padding-right: ac(12px, 8px);
        }

        @media (max-width: 1100px) {
          .product-card__info-sale, .product-card__info-price, .product-card__info-unit {
            font-size: 18px;
          }
        }
      }
    }

    @media (max-width: 1023px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: 551px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__result-pagination {
    margin-left: auto;
    margin-right: auto;
  }
}
